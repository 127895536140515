import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SetMissingOrganizationIdOnInvoicesParams, RecalculateInvoiceStatsParams } from '../../../../functions/src/upgrade/Upgrader';
import ControlPanelListTitle from './ControlPanelListTitle';
import UpgradeFunction from './UpgradeFunction';

type OrganizationUpgradeFunctionsParams = {
  
  organizationId: string;

};

const OrganizationUpgradeFunctions = ({ match }: RouteComponentProps<OrganizationUpgradeFunctionsParams>) => {

  const setMissingOrganizationIdOnInvoices: SetMissingOrganizationIdOnInvoicesParams = { organizationId: match.params.organizationId, functionName: 'setMissingOrganizationIdOnInvoices', preview: false };
  const recalculateInvoiceStats: RecalculateInvoiceStatsParams = { organizationId: match.params.organizationId, functionName: 'recalculateInvoiceStats', preview: false };

  return (
    <div className="page">
      <div className="dummy-top"></div>
      <h1>Organizacija</h1>
      <div className="panel">
        <div className="list">
          <ControlPanelListTitle>Upgrade functions</ControlPanelListTitle>
          <UpgradeFunction params={setMissingOrganizationIdOnInvoices} />
          <UpgradeFunction params={recalculateInvoiceStats} />
        </div>
      </div>
    </div>
  );

};

export default withRouter(OrganizationUpgradeFunctions);

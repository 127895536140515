
import React from 'react';
import InvoiceStatus from 'types/InvoiceStatus';
import InvoiceStatusSelectBox from './InvoiceStatusSelectBox';
import { StylesConfig } from 'react-select';
import InvoiceStatusUpdater from 'utils/InvoiceStatusUpdater';
import { useFirebase } from 'config/Firebase';

type InvoiceStatusBoxProps = {
  status: InvoiceStatus;
  invoiceId: string;
};

const InvoiceStatusBox = (props: InvoiceStatusBoxProps) => {

  const firebaseContext = useFirebase();
  const db = firebaseContext!.firebase.db();
  
  const handleStatusChange = (status: InvoiceStatus) => {
    const invoiceStatusUpdater = new InvoiceStatusUpdater(db, props.invoiceId);
    invoiceStatusUpdater.setStatus(status);
  };
  
  type OptionType = {
    label: string;
    value: string;
  };

  type IsMulti = false;

  const getBackgroundColor = () => {
    // Green
    if (props.status === 'Paid') {
      return '#cdfbd4';
    }

    // Gray
    return '#f9f9fb';
  };

  const getFontColor = () => {
    // Green
    if (props.status === 'Paid') {
      return '#088a68';
    }

    // Gray
    return '#4a4e55';
  };

  const style: StylesConfig<OptionType, IsMulti> = {
    singleValue: (provided, state) => ({
      ...provided,
      margin: 0,
      color: getFontColor()
    }),
    option: (provided, state) => ({
      ...provided,
      lineHeight: 1.5,
      color: '#242526',
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: 0,
      lineHeight: 1
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
      padding: 0,
      fontSize: 12,
      background: getBackgroundColor(),
      borderRadius: 6,
      minHeight: 20,
      lineHeight: 1,
      cursor: 'pointer',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '2px 7px'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      marginTop: 0,
      marginBottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: '5px 2px',
      color: getFontColor(),
      "&:hover": {
        color: getFontColor(),
      }
    })
  };
  const handleStatusClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
  };
  
  return (
    <div onClick={handleStatusClick} style={{ width: 120 }}>
      <InvoiceStatusSelectBox
        value={props.status}
        onChange={handleStatusChange}
        visible={undefined}
        label={undefined}
        fullLine={undefined}
        isClearable={undefined}
        style={style} />
    </div>
  );
};

export default InvoiceStatusBox;

import React from 'react';
import * as ControlPanelRoutes from 'constants/ControlPanelRoutes';
import { withRouter } from 'react-router-dom';
import Router from 'utils/Router';
import ControlPanelListTitle from './ControlPanelListTitle';
import ListLink from './ListLink';

const ControlPanel = () => {
 
  return (
    <div className="page">
      <div className="dummy-top"></div>
      <h1>Nadzorna plošča</h1>
      <div className="panel">
        <div className="list">
          <ControlPanelListTitle>Naziv</ControlPanelListTitle>
          <ListLink to={Router.route(ControlPanelRoutes.organizations)}>Organizacije</ListLink>
        </div>
      </div>
    </div>
  );

};

export default withRouter(ControlPanel);

import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import AuthenticatedRoute from 'components/AuthenticatedRoute.js';
import { GetAll } from 'components/Pages.js';

const App = () => {
  const pages = GetAll();
  return (
    <BrowserRouter>
      <Switch>
        {pages.map((route, index) => (
          <AuthenticatedRoute
            key={index}
            path={route.path}
            private={route.private}
            exact={route.exact}
            component={route.sidebar}
          />
        ))}
      </Switch>
      
      <Switch>
        {pages.map((route, index) => (
          <AuthenticatedRoute
            key={index}
            path={route.path}
            private={route.private}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
      
      <input type="hidden" name="fb-project-id" value={process.env.REACT_APP_FIREBASE_PROJECT_ID} />
      <input type="hidden" name="app-version" value={process.env.REACT_APP_VERSION} />
    </BrowserRouter>
  );
};

export default App

import React from 'react';
import InvoiceAttachmentFile from 'types/InvoiceAttachmentFile';
import InvoiceAttachment from './InvoiceAttachment';
import UploadAttachmentButton from './UploadAttachmentButton';

type InvoiceAttachmentListProps = {
  organizationId: string;
  attachments: InvoiceAttachmentFile[];
  onUploadStarted: (fileId: string, fileName: string) => void;
  onUploadProgress: (fileId: string, progress: number) => void;
  onUploadSuccess: (fileId: string, url: string) => void;
  onUploadError: (fileId: string, message: string) => void;
  onRemoved: (fileId: string) => void;
};

const InvoiceAttachmentList = (props: InvoiceAttachmentListProps) => {

  return (
    <div className="invoice-attachment-list">
      {props.attachments.map((attachment) => (
        <InvoiceAttachment key={attachment.id} id={attachment.id} url={attachment.url} fileName={attachment.fileName} errorMessage={attachment.errorMessage} uploading={attachment.uploading} uploadProgress={attachment.uploadProgress} onRemoveButtonClicked={props.onRemoved} />
      ))}
      <UploadAttachmentButton organizationId={props.organizationId} onUploadStarted={props.onUploadStarted} onUploadProgress={props.onUploadProgress} onUploadSuccess={props.onUploadSuccess} onUploadError={props.onUploadError} />
    </div>
  );
  
}

export default InvoiceAttachmentList;

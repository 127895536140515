import LastInvoicesPerYear from "types/LastInvoicesPerYear";

class InvoiceNumberProvider {

  static getInvoiceNumberText(invoiceNumber: number, invoiceNumberYear: number, prefixText: string) {
    const invoiceNumberFormat = "{prefix}{number}/{year}";
    const invoiceNumberText = invoiceNumberFormat
      .replace("{prefix}", prefixText)
      .replace("{number}", invoiceNumber.toString())
      .replace("{year}", invoiceNumberYear.toString());
    return invoiceNumberText;
  }
  
  static getNextInvoiceNumber(invoiceTypeId: string, year: number, lastInvoices: LastInvoicesPerYear[]) {
    if (!lastInvoices) {
      throw new Error('lastInvoices is set to undefined');    
    }

    const lastInvoicesPerYear = lastInvoices.filter(x => x.year === year)[0];
    if (lastInvoicesPerYear) {
      const lastInvoice = lastInvoicesPerYear.lastInvoices.filter(x => x.invoiceTypeId === invoiceTypeId)[0];
      if (lastInvoice) {
        return lastInvoice.invoiceNumber + 1;
      }
    }

    // There was no invoice issued this year yet, return 1
    return 1;
  }

}

export default InvoiceNumberProvider;

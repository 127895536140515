
import React from 'react';
import { Link } from 'react-router-dom';

type ListLinkProps = {

  to: string;

  children: React.ReactNode;

}

const ListLink = (props: ListLinkProps) => {
  return (
    <Link className="row data" to={props.to}>
      <div className="col main">
        {props.children}
      </div>
    </Link>
  );
}

export default ListLink;

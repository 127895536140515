import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SnackbarAlert = ({ content, handleClose, duration }) => {
  const handleCloseInternal = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleClose();
  };
  
  if (!content) {
    return <></>
  }

  const autoHideDuration = duration ? duration : 1600;

  return (
    <Snackbar open={true} autoHideDuration={autoHideDuration} onClose={handleCloseInternal}>
      <Alert onClose={handleClose} severity={content.severity}>
        {content.text}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;

import OrganizationData from "types/OrganizationData";

class DefaultVatPercentageProvider {

  static getDefaultVatPercentage(organizationData: OrganizationData) {
    // On existing invoices taxDebtor is undefined since we don't store it on invoice document.
    // That's why we need to have === true here.
    return organizationData.taxDebtor === true ? 22 : 0;
  }

}

export default DefaultVatPercentageProvider;

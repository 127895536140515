import React, { useState } from 'react';
import AlertModal from 'components/modals/AlertModal';

const useChangeAttributionTextModal = (onConfirm: (() => void)) => {
  
  const [opened, setOpened] = useState(false);
  const [potentialAttributionText, setPotentialAttributionText] = useState('');

  const close = () => {
    setOpened(false);
  };

  const confirm = () => {
    onConfirm();

    setOpened(false);
  };

  const open = () => {
    setOpened(true);
  };

  const render = () => {
    return (
      <AlertModal open={opened} onCancel={close} onConfirm={confirm} title="Želite spremeniti pripis računa?" text={`Pripis lahko spremenimo v "${potentialAttributionText}"`} />
    );
  };

  return {
    close,
    open,
    render,
    opened,
    potentialAttributionText,
    setPotentialAttributionText
  }

};

export default useChangeAttributionTextModal;

import regularFont from 'fonts/OpenSans/OpenSans-SemiBold.ttf';
import boldFont from 'fonts/OpenSans/OpenSans-Bold.ttf';
import { Font } from '@react-pdf/renderer';

const font = {
  size: {
    bigger: 12,
    big: 10,
    normal: 8,
    small: 7,
    smaller: 5
  }
};

const InvoicePdfStyle = {

  registerFonts: () => {
    Font.register({ family: 'OpenSans', src: regularFont });
    Font.register({ family: 'OpenSans', src: boldFont, fontWeight: 'bold' });
  },

  getStyle: (hasDiscount) => {
    const bigEntryWidth = hasDiscount ? 190 : 230;
    return {
      page: {
        fontFamily: 'OpenSans',
        lineHeight: 1.6,
        fontSize: font.size.normal,
        color: '#000000',
        padding: 50
      },
      partner: {
        fontSize: font.size.big,
        lineHeight: 2,
        width: 235,
        fontWeight: 'bold',
        minHeight: 115
      },
      organization: {
        width: 320,
        height: 95
      },
      company: {
      },
      flexRow: {
        flexDirection: 'row'
      },
      metadata: {
        width: 160,
        marginLeft: 10
      },
      metadataHeader: {
        width: 100
      },
      metadataValue: {
        marginLeft: 5
      },
      metadataRowInvoiceNumber: {
        borderBottomColor: '#000000',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        marginBottom: 5,
        marginTop: -4,
        alignItems: 'center'
      },
      metadataInvoiceNumber: {
        fontWeight: 'bold',
        fontSize: font.size.bigger
      },
      relative: {
        position: 'relative'
      },
      qrCode: {
        width: 80,
        height: 80,
        position: 'absolute',
        top: 35,
        right: 0
      },
      itemList: {
        width: 490,
        marginTop: 20,
        marginBottom: 30
      },
      itemListRow: {
        paddingVertical: 3
      },
      evenRow: {
        backgroundColor: 'white'
      },
      oddRow: {
        backgroundColor: 'white'
      },
      vatCategories: {
        width: 200,
        fontSize: font.size.smaller,
      },
      vatCategoryRow: {
        paddingVertical: 2
      },
      vatCategoryHeader: {
        paddingBottom: 3,
        borderBottomColor: '#000000',
        borderBottomStyle: 'solid',
        borderBottomWidth: 0.5
      },
      rowEntry: {
        marginRight: 5,
        width: 70,
      },
      bigEntry: {
        width: bigEntryWidth
      },
      smallEntry: {
        width: 35
      },
      itemListHeader: {
        paddingBottom: 5,
        fontSize: font.size.small
      },
      summary: {
        marginLeft: 290,
        width: 200
      },
      summaryRow: {
        paddingVertical: 3,
        flexDirection: 'row-reverse'
      },
      summaryValue: {
        marginLeft: 5,
      },
      borderBottom: {
        borderBottomColor: '#000000',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1
      },
      taxDescription: {
        marginTop: 30,
        fontSize: font.size.small
      },
      attribution: {
        marginTop: 10,
        width: 500,
        fontSize: font.size.small
      },
      signature: {
        textAlign: 'right'
      },
      signatureImageHolder: {
        marginTop: 10,   
        alignItems: "flex-end",
        flexGrow: 1
      },
      signatureImage: {
        width: '100%',
        objectFit: 'scale-down'
      },
      signatureText: {
        marginTop: 40
      },
      logoWatermark: {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        width: 124,
        marginLeft: -10,
        height: 60,
        paddingTop: 10
      },
      logoWatermarkSvg: {
        width: 20,
        height: 20
      },
      logoWatermarkText: {
        marginLeft: 5,
        marginTop: 5,
        fontSize: 6
      },
      annotations: {
        size12: {
          fontSize: 12
        },
        size11: {
          fontSize: 11
        },
        size10: {
          fontSize: 10
        },
        size9: {
          fontSize: 9
        },
        size8: {
          fontSize: 8
        },
        size7: {
          fontSize: 7
        },
      }
    };
  }

};

export default InvoicePdfStyle;

import React, { useEffect, useState, useCallback, useReducer } from 'react';
import useCollection from 'hooks/useCollection.js';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import InvoiceNumberProvider from 'utils/InvoiceNumberProvider';
import Bugsnag from '@bugsnag/js';
import InvoiceTypeProvider from 'utils/InvoiceTypeProvider';
import LastInvoicesPerYear from 'types/LastInvoicesPerYear';
import OrganizationData from 'types/OrganizationData';
import InvoiceTypeLocalizer from 'utils/InvoiceTypeLocalizer';
import LastInvoiceSetter from 'utils/LastInvoiceSetter';
import OrganizationProvider from 'providers/OrganizationProvider';
import InvoiceNumberTextBox, { InvoiceTypeState } from 'components/forms/InvoiceNumberTextBox';
import InvoiceNumberPrefixTextProvider from 'utils/InvoiceNumberPrefixTextProvider';
import InvoiceNumberPrefixText from 'types/InvoiceNumberPrefixText';
import DisclaimerText from 'components/forms/DisclaimerText';

type InvoiceNumberingModalProps = {
  organizationId: string;
  visibleInvoiceTypeIdFilter?: string | undefined;
  open: boolean;
  onSave: (nextInvoiceNumber: LastInvoicesPerYear[], invoiceNumberPrefixTexts: InvoiceNumberPrefixText[]) => void;
  onClose: () => void;
}

const InvoiceNumberingModal = (props: InvoiceNumberingModalProps) => {

  const organizationsCollection = useCollection('organizations');
  const [organizationData, setOrganizationData] = useState<OrganizationData | null>(null);
  const year = new Date().getFullYear();

  type State = {
    invoiceTypes: InvoiceTypeState[]
  }
  
  const emptyState: State = {
    invoiceTypes: []
  }

  enum ActionType {
    Initialize,
    ItemSet
  }
  
  type InitializeAction = {
    type: ActionType,
    state: State
  }
  
  type ItemSetAction = {
    type: ActionType,
    itemState: InvoiceTypeState
  }
  
  const reducer = (state: State, action: InitializeAction | ItemSetAction) => {
    switch (action.type) {
      case ActionType.Initialize:
        const initializeAction = action as InitializeAction;
        return initializeAction.state;
      case ActionType.ItemSet: {
        const itemSetAction = action as ItemSetAction;
        const newState = {
          ...state
        };

        let index = -1;

        newState.invoiceTypes.forEach((invoiceType, i) => {
          if (invoiceType.id === itemSetAction.itemState.id) {
            index = i;
          }
        });

        if (index === -1) {
          throw new Error('Unable to find invoice type item: ' + itemSetAction.itemState.id);
        }

        newState.invoiceTypes[index] = itemSetAction.itemState;

        return newState;
      }
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, emptyState);

  const [saving, setSaving] = useState(false);

  const close = () => {
    reset();
    props.onClose();
  };
  
  const save = useCallback(async () => {
    if (!organizationData) {
      return;
    }

    setSaving(true);

    try {
      let lastInvoicesPerYear: LastInvoicesPerYear[] = organizationData.lastInvoices;
      
      state.invoiceTypes.forEach(invoiceType => {        
        const lastInvoiceSetter = new LastInvoiceSetter(lastInvoicesPerYear);
        lastInvoicesPerYear = lastInvoiceSetter.setLastInvoice(invoiceType.id, year, parseInt(invoiceType.nextInvoiceNumberText) - 1, '');
      });

      organizationData.lastInvoices = lastInvoicesPerYear;
      
      let invoiceNumberPrefixTexts: InvoiceNumberPrefixText[] = [];

      state.invoiceTypes.forEach(invoiceType => {
        const invoiceNumberPrefixText: InvoiceNumberPrefixText = {
          invoiceTypeId: invoiceType.id,
          prefixText: invoiceType.prefixText
        };
        invoiceNumberPrefixTexts.push(invoiceNumberPrefixText);
      });

      organizationData.invoiceNumberPrefixTexts = invoiceNumberPrefixTexts;
      
      await organizationsCollection.addOrUpdate(props.organizationId, organizationData);
      reset();
      props.onSave(organizationData.lastInvoices as LastInvoicesPerYear[], organizationData.invoiceNumberPrefixTexts);
    }
    catch (error) {
      Bugsnag.notify(error);
      console.log('Error while saving organization: ' + error);
    }
    
    setSaving(false);
  }, [ organizationData, organizationsCollection, props, state.invoiceTypes, year ]);

  const reset = () => {
    setOrganizationData(null);
  };

  const handleChange = (newState: InvoiceTypeState) => {
    const action: ItemSetAction = {
      type: ActionType.ItemSet,
      itemState: newState
    };
    dispatch(action);
  };

  useEffect(() => {   
    const getOrganization = async () => {
      const rawData = await organizationsCollection.get(props.organizationId);
      if (!rawData) {
        return;
      }

      const data = new OrganizationProvider().get(rawData);
      
      const initialState: State = {
        invoiceTypes: []
      };

      const invoiceTypes = InvoiceTypeProvider.getAll();
      invoiceTypes.forEach(invoiceType => {
        const nextInvoiceNumber = InvoiceNumberProvider.getNextInvoiceNumber(invoiceType.id, year, data.lastInvoices);
        const invoiceTypeState: InvoiceTypeState = {
          label: InvoiceTypeLocalizer.getNextInvoiceNumberText(invoiceType.id),
          id: invoiceType.id,
          defaultNextInvoiceNumberText: nextInvoiceNumber.toString(),
          nextInvoiceNumberText: nextInvoiceNumber.toString(),
          year: year,
          prefixText: InvoiceNumberPrefixTextProvider.getPrefixText(data.invoiceNumberPrefixTexts, invoiceType.id),
          visible: props.visibleInvoiceTypeIdFilter !== undefined ? invoiceType.id === props.visibleInvoiceTypeIdFilter : true
        };
        initialState.invoiceTypes.push(invoiceTypeState);
      });

      const action: InitializeAction = {
        type: ActionType.Initialize,
        state: initialState
      }
      dispatch(action);
      
      setOrganizationData(data);
    };
    
    if (!organizationData && props.open) {
      getOrganization();
    }
  }, [ props.organizationId, props.visibleInvoiceTypeIdFilter, props.open, year, organizationData, organizationsCollection, ActionType.Initialize ]);

  const isFetchingData = !organizationData;
  if (isFetchingData || !props.open) {
    return <></>
  }

  const textBoxes = state.invoiceTypes.map(invoiceType => (
    <InvoiceNumberTextBox key={invoiceType.id} state={invoiceType} onChange={handleChange} />
  ));

  return (
    <Dialog open={props.open} onClose={close} className="modal-content">
      <div className="close-button" onClick={close}>
        <CloseIcon />
      </div>
      <h1 className="title">Številčenje računov</h1>
      <div className="split-table">
        {textBoxes}
      </div>
      <DisclaimerText>
        Letnico v številki računa lahko spremenite s spremembo leta v datumu storitve računa.
      </DisclaimerText>
      <div className="button-bottom-bar r-align">
        <button className="buttonish text-only" onClick={save} disabled={saving}>Shrani</button>
      </div>
    </Dialog>
  );
};

export default InvoiceNumberingModal;

import firebase from 'firebase/app';
import OrganizationProvider from './OrganizationProvider';

class OrganizationDataProvider {

  private db: firebase.firestore.Firestore;
  
  private organizationsCollection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(db: firebase.firestore.Firestore) {
    this.db = db;
    this.organizationsCollection = this.db.collection('organizations');
  }

  async getOrganizationData(organizationId: string) {
    console.log(`Getting data for organization: ${organizationId} ...`);

    const query = this.organizationsCollection.doc(organizationId);
    const document = await query.get();
    if (!document.exists) {
      return null;
    }

    const rawData = document.data();
    if (!rawData) {
      return null;
    }
    
    console.log(`Got organization data for organization ${organizationId}`);

    const organizationData = new OrganizationProvider().get(rawData);
    return organizationData;
  }

}

export default OrganizationDataProvider;

const getTaxNumberString = (taxNumber) => {
  if (!taxNumber) {
    return null;
  }

  if (!taxNumber.length) {
    return null;
  }

  return 'ID za DDV: ' + taxNumber;
};

export default { getTaxNumberString };

import { useState } from 'react';

const useInvoiceButtonState = () => {
  
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [createInvoiceButtonDisabled, setCreateInvoiceButtonDisabled] = useState(false);
  const [downloadPdfButtonDisabled, setDownloadPdfButtonDisabled] = useState(false);
  const [downloadXmlButtonDisabled, setDownloadXmlButtonDisabled] = useState(false);
  const [printPdfButtonDisabled, setPrintPdfButtonDisabled] = useState(false);

  return {
    saveButtonDisabled,
    setSaveButtonDisabled,
    sendButtonDisabled,
    setSendButtonDisabled,
    createInvoiceButtonDisabled,
    setCreateInvoiceButtonDisabled,
    downloadPdfButtonDisabled,
    setDownloadPdfButtonDisabled,
    downloadXmlButtonDisabled,
    setDownloadXmlButtonDisabled,
    printPdfButtonDisabled,
    setPrintPdfButtonDisabled
  };

};

export default useInvoiceButtonState;

import { useReducer } from "react";

export type Currency = {
  id: string
  paid: number
  notPaid: number
}

const useInvoiceStats = () => {

  interface State {
    currencies: Currency[]
    selectedCurrency: string
    selectedYear: number
    loading: boolean
  }
  
  type Action =
    | { type: 'add'; currency: Currency }
    | { type: 'update'; currency: Currency }
    | { type: 'selectCurrency'; id: string }
    | { type: 'selectYear'; year: number }
    | { type: 'reset' }
    
  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case 'add':
        return {
          ...state,
          loading: false,
          currencies: [
            ...state.currencies,
            action.currency
          ],
        };
      case 'update':
        let found = false;
        state.currencies.forEach(currency => {
          if (currency.id === action.currency.id) {
            found = true;
          }
        })

        // If element is not found inside collection, then we add it to it.
        if (!found) {
          return reducer(state, { ...action, type: 'add' });
        }
  
        return {
          ...state,
          loading: false,
          currencies: state.currencies.map((currency) => {
            if (currency.id === action.currency.id) {
              return action.currency;
            }
  
            return currency;
          }),
        };
      case 'selectCurrency':
        return {
          ...state,
          selectedCurrency: action.id,
          loading: false
        };
      case 'selectYear':
        return {
          ...state,
          selectedYear: action.year,
          loading: true
        };
      case 'reset':
        return {
          ...initialState,
          selectedYear: state.selectedYear,
          loading: false
        };
    }
  }

  const defaultCurrency = {
    id: 'EUR',
    paid: 0,
    notPaid: 0
  };
  
  const initialState = {
    currencies: [
      defaultCurrency
    ],
    selectedCurrency: defaultCurrency.id,
    selectedYear: new Date().getFullYear(),
    loading: true
  };
  return useReducer(reducer, initialState);

};

export default useInvoiceStats;

import React, { useEffect } from 'react';
import Invoice from 'components/documents/invoice/Invoice.js';
import useQueryParam from 'hooks/useQueryParam.js';
import { withRouter, useParams } from 'react-router-dom';
import { useFirebase } from 'config/Firebase';

const Document = (page) => {

  const { documentId } = useParams();
  const invoiceId = documentId === 'new' ? null : documentId;
  const organizationId = useQueryParam('organizationId');
  const fromInvoiceId = useQueryParam('fromInvoiceId');
  const invoiceTypeId = useQueryParam('typeId');
  const firebaseContext = useFirebase();

  useEffect(() => {
    firebaseContext.firebase.logEvent('view_item', { item_id: documentId });
  }, [ firebaseContext.firebase, documentId ]);

  return (
    <Invoice invoiceId={invoiceId} organizationId={organizationId} invoiceTypeId={invoiceTypeId} fromInvoiceId={fromInvoiceId} history={page.history} />
  );
};

export default withRouter(Document);

import Bugsnag from "@bugsnag/js";
import { useEffect, useState } from "react";
import { useAuth } from "utils/Auth";
import EmailEvent from '../types/EmailEvent';
import { AxiosResponse } from "axios";
import PromiseHelper from "utils/PromiseHelper";

const useEmailHistory = (invoiceId: string, messageIds: string[]) => {
  
  const [emailEvents, setEmailEvents] = useState<EmailEvent[]>();
  const { userData, createBackendRequest } = useAuth();
  
  useEffect(() => {
    const getEmailHistory = async () => {
      try {
        const axiosInstance = createBackendRequest();

        const promises: Promise<AxiosResponse<EmailEvent[]>>[] = [];

        messageIds.forEach(messageId => {
          const promise = axiosInstance.get(`/invoices/${invoiceId}/email/${messageId}/history`);
          promises.push(promise);
        })
        
        const results = await Promise.allSettled(promises);
        const values = results.filter(PromiseHelper.isFulfilled).map(p => p.value);

        const emailEvents = values.flatMap(value => value.data);
        setEmailEvents(emailEvents);
      }
      catch (error) {
        Bugsnag.notify(error);
        console.log(`Error while getting email history: ${error}`);
        setEmailEvents([]);
      }
    };

    if (userData && emailEvents === undefined) {
      if (messageIds.length > 0) {
        getEmailHistory();
      } else {
        setEmailEvents([]);
      }
    }
  }, [userData, messageIds, emailEvents, invoiceId, createBackendRequest]);
  
  return {
    emailEvents
  };

};

export default useEmailHistory;

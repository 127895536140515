import SubscriptionData from "types/SubscriptionData";
import SubscriptionTypeProvider from "utils/SubscriptionTypeProvider";
import firebase from 'firebase/app';

class SubscriptionsProvider {

  private db: firebase.firestore.Firestore;
  
  private organizationId: string;
  
  private subscriptionsCollection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(db: firebase.firestore.Firestore, organizationId: string) {
    this.db = db;
    this.organizationId = organizationId;
    this.subscriptionsCollection = this.db.collection('organizations').doc(this.organizationId).collection('subscriptions');
  }

  async getActiveSubscriptions() {
    let activeSubscriptions: SubscriptionData[] = [];
    
    const query = this.subscriptionsCollection.where('active', '==', true);
    const documents = await query.get();

    documents.forEach(document => {
      const activeSubscription = this.getSubscription(document.id, document.data());
      activeSubscriptions.push(activeSubscription);
    });

    return activeSubscriptions;
  }

  async activateSubscription(subscriptionTypeId: string) {
    const subscriptionDocumentData = {
      activatedAt: firebase.firestore.Timestamp.now(),
      subscriptionTypeId: subscriptionTypeId,
      active: true
    };
    
    await this.subscriptionsCollection.add(subscriptionDocumentData);
  }

  async deactivateSubscription(subscriptionId: string) {
    const subscriptionDocumentRef = this.subscriptionsCollection.doc(subscriptionId);
    await subscriptionDocumentRef.update({
      active: false,
      deactivatedAt: firebase.firestore.Timestamp.now()
    });
  }
  
  getSubscription(subscriptionId: string, rawData: firebase.firestore.DocumentData) {
    const subscriptionType = SubscriptionTypeProvider.getById(rawData.subscriptionTypeId);
    const subscriptionData: SubscriptionData = {
      id: subscriptionId,
      activatedAt: rawData.activatedAt.toDate(),
      deactivatedAt: rawData.deactivatedAt ? rawData.deactivatedAt.toDate() : undefined,
      type: subscriptionType,
      active: rawData.active,
      needsPaymentInfoUpdate: rawData.needsPaymentInfoUpdate,
      stripeSubscriptionId: rawData.stripeSubscriptionId,
      stripeCustomerId: rawData.stripeCustomerId,
      stripePriceId: rawData.stripePriceId
    };
    return subscriptionData;
  }

}

export default SubscriptionsProvider;

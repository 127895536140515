import React from 'react';
import { Link } from 'react-router-dom';

type ListItemProps = {
  to?: string | undefined,
  onClick?: () => void | undefined;
  className?: string | undefined,
  children: any
}

const ListItem = (props: ListItemProps) => {

  const getClassName = () => {
    let className = "";

    if (props.className) {
      className += props.className;
    }

    if (!props.to && !props.onClick) {
      className += " readonly";
    }

    return className;
  };

  if (props.to) {    
    return (
      <Link to={props.to} className={getClassName()}>
        {props.children}
      </Link>
    );
  }

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <span className={getClassName()} onClick={handleClick}>
      {props.children}
    </span>
  );

};

export default ListItem;

import React from 'react';
import { withRouter, useParams } from 'react-router-dom';
import ErrorView from 'components/ErrorView.js';

const Error = () => {

  const params = useParams();
 
  return (
    <ErrorView errorId={params.errorId} />
  );
};

export default withRouter(Error);

import React from "react";
import InvoiceItemLine from "./InvoiceItemLine";
import InvoiceItemHeader from "./InvoiceItemHeader";
import { ReactComponent as AddIcon } from 'icons/add.svg';
import TaxDescription from "./TaxDescription";
import InvoiceSummary from "./InvoiceSummary";
import InvoiceItemModel from "types/InvoiceItemModel";
import InvoiceItemListSummary from "types/InvoiceItemListSummary";

type InvoiceItemListProps = {
  currency: string;
  data: InvoiceItemModel[];
  summary: InvoiceItemListSummary;
  isAddButtonEnabled: boolean;
  onAddButtonClicked: () => void;
  onRemoveButtonClicked: (model: InvoiceItemModel) => void;
  onNameChanged: (model: InvoiceItemModel, newName: string) => void;
  onUnitChanged: (model: InvoiceItemModel, newUnit: string) => void;
  onQuantityChanged: (model: InvoiceItemModel, value: number) => void;
  onPriceChanged: (model: InvoiceItemModel, value: number) => void;
  onVatPercentageChanged: (model: InvoiceItemModel, value: number) => void;
  onDiscountPercentageChanged: (model: InvoiceItemModel, value: number) => void;
  taxDescriptions: string[];
  taxDescription: string;
  onTaxDescriptionChange: (selectedTaxDescription: string) => {};
}

const InvoiceItemList = (props: InvoiceItemListProps) => {

  const handleAddButtonClicked = (event: React.MouseEvent) => {
    event.preventDefault();

    props.onAddButtonClicked();
  };
  
  return (
    <div className="invoice-item-list">
      <InvoiceItemHeader />      
      {props.data.map((model, index) => (
        <InvoiceItemLine
          key={model.item.id}
          currency={props.currency}
          model={model}
          removeButtonVisible={index > 0 || props.data.length !== 1}
          onRemoveButtonClicked={props.onRemoveButtonClicked}
          onNameChanged={props.onNameChanged}
          onUnitChanged={props.onUnitChanged}
          onQuantityChanged={props.onQuantityChanged}
          onPriceChanged={props.onPriceChanged}
          onVatPercentageChanged={props.onVatPercentageChanged}
          onDiscountPercentageChanged={props.onDiscountPercentageChanged} />
      ))}
      
      <div className="after-table-buttons">
        <button className="buttonish secondary" type="button" onClick={handleAddButtonClicked} disabled={!props.isAddButtonEnabled}>
          <AddIcon />
          Dodaj postavko
        </button>
      </div>

      <InvoiceSummary summary={props.summary} currency={props.currency} />

      <div className="clear-float"></div>

      <TaxDescription values={props.taxDescriptions} value={props.taxDescription} onChange={props.onTaxDescriptionChange} />

    </div>
  )

};

export default InvoiceItemList;

import React from 'react';
import { useAuth } from 'utils/Auth';
import UpgraderClient from 'utils/UpgraderClient';
import { UpgradeParams } from '../../../../functions/src/upgrade/Upgrader';

type UpgradeFunctionProps = {

  params: UpgradeParams;

};

const UpgradeFunction = (props: UpgradeFunctionProps) => {
  
  const { createBackendRequest } = useAuth();

  const runUpgrade = (preview: boolean) => {
    const axiosInstance = createBackendRequest();
    const upgraderClient = new UpgraderClient(axiosInstance);
    const params = props.params;
    params.preview = preview;
    upgraderClient.upgrade(params);
  };

  return (
    <div className="row data readonly">
      <div className="col main">
        {props.params.functionName}
      </div>
      <div className="col">
        <span className="link" onClick={() => runUpgrade(false)}>Run</span> | 
        <span className="link" onClick={() => runUpgrade(true)}>Preview</span>
      </div>
    </div>
  )

};

export default UpgradeFunction;

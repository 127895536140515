import React, { useEffect, useReducer } from 'react';
import Payment from './Payment';
import PaymentInfo from 'types/PaymentInfo';
import { ReactComponent as AddIcon } from 'icons/add.svg';
import { v4 } from 'uuid';
import { useFirebase } from 'config/Firebase';
import PaymentHistoryProvider from 'utils/PaymentHistoryProvider';
import { useAuth } from 'utils/Auth';
import PaymentProgress from './PaymentProgress';
import InvoiceStatusUpdater from 'utils/InvoiceStatusUpdater';
import InvoiceStatus from 'types/InvoiceStatus';

type PaymentHistoryModalFormProps = {
  payments?: PaymentInfo[] | undefined;
  priceSum: number;
  currency: string;
  invoiceId: string;
  saving: boolean;
  onSavingChanged: (newValue: boolean) => void;
  onSaveSuccess: (newStatus: InvoiceStatus | null) => void;
}

const PaymentHistoryModalForm = (props: PaymentHistoryModalFormProps) => {
  
  const firebaseContext = useFirebase();
  const db = firebaseContext!.firebase.db();
  const auth = useAuth();
  
  type State = {
    payments: PaymentInfo[],
    error?: string | undefined,
    initialized: boolean
  }

  const newPayment = () => {
    const paymentId = v4();
    const payment: PaymentInfo = {
      id: paymentId,
      amount: 0,
      date: new Date(),
      note: ''
    };
    return payment;
  }
  
  const emptyState: State = {
    payments: props.payments ? props.payments.length > 0 ? props.payments : [newPayment()] : [],
    initialized: props.payments ? true : false
  }

  enum ActionType {
    Initialize,
    AddPayment,
    UpdatePayment,
    RemovePayment
  }
  
  type InitializeAction = {
    type: ActionType,
    state: State
  }
  
  type PaymentAction = {
    type: ActionType,
    payment: PaymentInfo
  }
    
  const reducer = (state: State, action: InitializeAction | PaymentAction) => {
    switch (action.type) {
      case ActionType.Initialize:
        const initializeAction = action as InitializeAction;
        return initializeAction.state;
      case ActionType.AddPayment: {        
        const paymentAction = action as PaymentAction;
        const newState = {
          ...state,
          payments: [
            ...state.payments,
            paymentAction.payment
          ]
        };
        return newState;
      }
      case ActionType.UpdatePayment: {
        const paymentAction = action as PaymentAction;
        const newState = {
          ...state
        };

        let index = -1;

        newState.payments.forEach((payment, i) => {
          if (payment.id === paymentAction.payment.id) {
            index = i;
          }
        });

        if (index === -1) {
          throw new Error('Unable to find payment: ' + paymentAction.payment.id);
        }

        newState.payments[index] = paymentAction.payment;

        return newState;
      }
      case ActionType.RemovePayment: {        
        const paymentAction = action as PaymentAction;
        const newState = {
          ...state,
          payments: [
            ...state.payments.filter(x => x.id !== paymentAction.payment.id)
          ]
        };
        return newState;
      }
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, emptyState);

  const handleChange = (newState: PaymentInfo) => {
    const action: PaymentAction = {
      type: ActionType.UpdatePayment,
      payment: newState
    };
    dispatch(action);
  }

  const handleRemove = (removedPayment: PaymentInfo) => {
    const action: PaymentAction = {
      type: ActionType.RemovePayment,
      payment: removedPayment
    };
    dispatch(action);
  }

  const handleAddButtonClicked = () => {
    if (props.saving) {
      return;
    }
    
    const action: PaymentAction = {
      type: ActionType.AddPayment,
      payment: newPayment()
    };
    dispatch(action);
  }

  const getPaidAmount = () => {
    return state.payments.map(x => x.amount).reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    }, 0);
  }
  const paidAmount = getPaidAmount();

  const content = state.payments.map((payment, i) => (
    <Payment key={payment.id} state={payment} index={i} autoFocus={i === state.payments.length - 1 && payment.amount === 0} removeButtonVisible={state.payments.length > 1} currency={props.currency} onChange={handleChange} onRemove={handleRemove} />
  ));

  const error = state.error ? (
    <div className="modal-error-message">{state.error}</div>
  ) : null;

  const getNewInvoiceStatus = (): InvoiceStatus | null => {
    if (Math.abs(props.priceSum - paidAmount) <= 0.01) {
      return 'Paid';
    }

    if (paidAmount < props.priceSum && paidAmount > 0.01) {
      return 'PartiallyPaid'
    }

    if (paidAmount > props.priceSum) {
      return 'Paid';
    }

    return null;
  }

  const save = async () => {    
    props.onSavingChanged(true);
    
    try {
      let promises = [];

      const paymentHistoryProvider = new PaymentHistoryProvider(db, auth.currentUser.uid, props.invoiceId);
      const paymentHistorySavePromise = paymentHistoryProvider.save(state.payments);
      promises.push(paymentHistorySavePromise);

      const newInvoiceStatus = getNewInvoiceStatus();
      if (newInvoiceStatus) {
        const invoiceStatusUpdater = new InvoiceStatusUpdater(db, props.invoiceId);
        const invoiceStatusChangePromise = invoiceStatusUpdater.setStatus(newInvoiceStatus);
        promises.push(invoiceStatusChangePromise);
      }

      await Promise.allSettled(promises);

      props.onSaveSuccess(newInvoiceStatus);
    }
    finally {
      props.onSavingChanged(false);
    }
  }

  useEffect(() => {
    const initialize = (payments: PaymentInfo[], error?: string | undefined) => {
      const initialState: State = {
        payments: payments,
        initialized: true,
        error: error
      };
      const action: InitializeAction = {
        type: ActionType.Initialize,
        state: initialState
      }
      dispatch(action);
    }

    const getInitialState = async () => {
      try {
        const paymentHistoryProvider = new PaymentHistoryProvider(db, auth.currentUser.uid, props.invoiceId);
        const payments = await paymentHistoryProvider.get();
        initialize(payments.length > 0 ? payments : [newPayment()]);
      }
      catch (error) {
        initialize([], 'Pri pridobivanju zgodovine plačil je prišlo do napake.');
      }
    }
    
    if (!state.initialized) {
      getInitialState();
    }
  });

  const detailsVisible = state.initialized && !state.error;
  const addButton = detailsVisible ? (
    <div className="after-table-buttons">
      <button className="buttonish secondary" type="button" onClick={handleAddButtonClicked}>
        <AddIcon />
        Dodaj plačilo
      </button>
    </div>
  ) : null;

  const priceSum = detailsVisible ? (
    <PaymentProgress payments={state.payments} paidAmount={paidAmount} priceSum={props.priceSum} currency={props.currency} />
  ) : null;

  return (
    <>
      {content}
      {error}
      {addButton}
      {priceSum}
      <div className="button-bottom-bar r-align">
        <button className="buttonish text-only" type="button" disabled={props.saving} onClick={save}>Shrani</button>
      </div>
    </>
  );
};

export default PaymentHistoryModalForm;

const Localizer = {

  getNumberString: (number, numberOfMinDecimals, useZeroDecimalsIfInteger) => {
    let numberOfDecimals = numberOfMinDecimals;

    if (useZeroDecimalsIfInteger === true) {
      const isInteger = parseInt(number) === number;
      if (isInteger) {
        numberOfDecimals = 0;
      }
    }

    return Localizer.getNumberStringForLanguage(number, numberOfDecimals, navigator.language);
  },

  getNumberStringForLanguage: (number, numberOfDecimals, language) => {
    if (!language) {
      language = 'en';
    }

    if (!number) {
      return Number(0).toLocaleString(language, { minimumFractionDigits: numberOfDecimals, maximumFractionDigits: numberOfDecimals });
    }

    return Number(number).toLocaleString(language, { minimumFractionDigits: numberOfDecimals, maximumFractionDigits: numberOfDecimals });
  },

  getDecimalSeparator: (locale) => {
    const numberWithDecimalSeparator = 1.1;

    const separator = numberWithDecimalSeparator
        .toLocaleString(locale)
        .substring(1, 2);

    return separator;
  },

  getThousandsSeparator: (locale) => {
    const numberWithThousands = 1000;

    const separator = numberWithThousands
        .toLocaleString(locale)
        .substring(1, 2);

    return separator;
  },

  parseFloat: (stringValue) => {
    if (!stringValue) {
      return 0;
    }

    const thousandsSeparator = Localizer.getThousandsSeparator();
    const stringWithoutThousandsSeparator = stringValue.split(thousandsSeparator).join('');
    const stringWithEnglishDecimalSeparator = stringWithoutThousandsSeparator.replace(',', '.');
    const floatValue = parseFloat(stringWithEnglishDecimalSeparator);

    console.log(`Parse float, stringValue: ${stringValue}, floatValue: ${floatValue}, thousandsSeparator: ${thousandsSeparator}, stringWithoutThousandsSeparator: ${stringWithoutThousandsSeparator}, stringWithEnglishDecimalSeparator: ${stringWithEnglishDecimalSeparator}`);

    return floatValue;
  }

};

export default Localizer;

import SubscriptionPrice, { SubscriptionPriceType } from '../types/SubscriptionPrice';
import SubscriptionType from '../types/SubscriptionType';

export default class SubscriptionTypeProvider {

  private static all: SubscriptionType[] = [
    {
      id: '44a8449e-ce74-4611-b2c0-69f0c44c9bf2', // Silver
      hasWatermark: false,
      hasPhoneSupport: true,
      isMostPopular: true,
      prices: [
        {
          id: process.env.REACT_APP_PRICE_ID_SILVER_MONTHLY!,
          text: '4,50 €',
          type: SubscriptionPriceType.Monthly
        },
        {
          id: process.env.REACT_APP_PRICE_ID_SILVER_YEARLY!,
          text: '48,60 €',
          type: SubscriptionPriceType.Yearly
        }
      ],
      maximumNumberOfInvoicesPerMonth: 30
    },
    {
      id: 'b9b412c9-ebe3-40a6-bbd2-187c3301c36a', // Gold
      hasWatermark: false,
      hasPhoneSupport: true,
      isMostPopular: false,
      prices: [
        {
          id: process.env.REACT_APP_PRICE_ID_GOLD_MONTHLY!,
          text: '9,50 €',
          type: SubscriptionPriceType.Monthly
        },
        {
          id: process.env.REACT_APP_PRICE_ID_GOLD_YEARLY!,
          text: '99,00 €',
          type: SubscriptionPriceType.Yearly
        }
      ],
      maximumNumberOfInvoicesPerMonth: Number.MAX_VALUE
    }
  ];

  static getById(id: string): SubscriptionType {    
    let foundSubscriptionType: SubscriptionType | null = null;
    
    this.all.forEach(subscriptionType => {
      if (subscriptionType.id === id) {
        foundSubscriptionType = subscriptionType;
      }
    });

    if (!foundSubscriptionType) {
      throw new Error(`Unable to find subscription type: ${id}`)
    }

    return foundSubscriptionType;
  }

  static getPriceById(priceId: string): SubscriptionPrice {    
    let foundSubscriptionPrice: SubscriptionPrice | null = null;
    
    this.all.forEach(subscriptionType => {
      subscriptionType.prices.forEach(subscriptionPrice => {
        if (subscriptionPrice.id === priceId) {
          foundSubscriptionPrice = subscriptionPrice;
        }
      });
    });

    if (!foundSubscriptionPrice) {
      throw new Error(`Unable to find subscription type by price: ${priceId}`)
    }

    return foundSubscriptionPrice;
  }

  static getAll() {
    SubscriptionTypeProvider.throwIfInvalidConfiguration();
    
    return this.all;
  }

  static getDefault() {
    return this.all[0];
  }

  private static throwIfInvalidConfiguration() {
    SubscriptionTypeProvider.throwIfUndefinedOrEmpty(process.env.REACT_APP_PRICE_ID_SILVER_MONTHLY, 'PRICE_ID_SILVER_MONTHLY');
    SubscriptionTypeProvider.throwIfUndefinedOrEmpty(process.env.REACT_APP_PRICE_ID_SILVER_YEARLY, 'PRICE_ID_SILVER_YEARLY');
    SubscriptionTypeProvider.throwIfUndefinedOrEmpty(process.env.REACT_APP_PRICE_ID_GOLD_MONTHLY, 'PRICE_ID_GOLD_MONTHLY');
    SubscriptionTypeProvider.throwIfUndefinedOrEmpty(process.env.REACT_APP_PRICE_ID_GOLD_YEARLY, 'PRICE_ID_GOLD_YEARLY');
  }

  private static throwIfUndefinedOrEmpty(value: string | undefined, name: string) {
    if (!value || value.trim().length === 0) {
      throw new Error(`${name} not defined`);
    }
  }
  
}

import React from 'react';
import { ReactComponent as CheckmarkIcon } from 'icons/checkmark.svg';

const CheckBox = ({ label, description, className, disabled, fullLine, spacing, ...other}, ref) => {

  const onClick = (event) => {
    const input = event.target;
    input.blur();
  };

  const getCssClassName = () => {
    let cssClassName = "check-box control-row";
    
    if (className) {
      cssClassName += ` ${className}`;
    }

    if (fullLine) {
      cssClassName += " full-line";
    }

    if (disabled) {
      cssClassName += " disabled";
    }

    return cssClassName;
  };

  const spacingHtml = spacing ? (<span className="text">&nbsp;</span>) : null;
  const descriptionHtml = description ? (<div className="description">{description}</div>) : null;
  const labelClass = description ? "label with-description" : "label";

  return (
    <label className={getCssClassName()}>
      {spacingHtml}
      <div className="container">
        <input ref={ref} type="checkbox" onClick={onClick} disabled={disabled} {...other} />
        <div className="checkmark inputish"><CheckmarkIcon /></div>
        <div className={labelClass}>
          {label}
          {descriptionHtml}
        </div>
      </div>
    </label>
  );
};

export default React.forwardRef(CheckBox);

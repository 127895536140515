import React from 'react';
import Localizer from 'utils/Localizer';

type InvoiceStatsBoxProps = {

  title: string;
  
  subtitle?: React.ReactNode;

  sum: number;

  currency: string;

  onCurrencyClicked: () => void;

  clickable: boolean;

  loading: boolean;

  children?: React.ReactNode;

}

const InvoiceStatsBox = (props: InvoiceStatsBoxProps) => {

  const handleCurrencyClicked = () => {
    if (props.onCurrencyClicked) {
      props.onCurrencyClicked();
    }
  };

  const getClassName = () => {
    let className = "sum";

    if (props.loading) {
      className += " loading";
    }

    return className;
  }

  const getCurrencySymbol = () => {
    if (props.currency.trim() === 'EUR') {
      return '€'
    }

    if (props.currency.trim() === 'USD') {
      return '$'
    }

    return props.currency;
  }

  const className = getClassName();

  const currencySymbol = getCurrencySymbol();

  return (
    <div className="box">
      {props.children}
      <div className="texts">
        <div className="title">
          {props.title}
          {props.subtitle}
        </div>
        <button className={className} onClick={handleCurrencyClicked} disabled={!props.clickable}>
          {Localizer.getNumberString(props.sum, 2)} {currencySymbol}
        </button>
      </div>
    </div>
  );

};

export default InvoiceStatsBox;

import UserData from 'types/UserData';
import firebase from 'firebase/app';

class UserProvider {

  private db: firebase.firestore.Firestore;
  
  private usersCollection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(db: firebase.firestore.Firestore) {
    this.db = db;
    this.usersCollection = this.db.collection('users');
  }

  async getUserData(userId: string) {
    console.log(`Getting user data for user: ${userId} ...`);

    const query = this.usersCollection.doc(userId);
    const document = await query.get();
    if (!document.exists) {
      return null;
    }

    console.log(`Got user data for user ${userId}`);

    return document.data() as UserData;
  }

}

export default UserProvider;

import React from 'react';
import PaymentInfo from 'types/PaymentInfo';
import Localizer from 'utils/Localizer';

type PaymentProgressProps = {
  payments: PaymentInfo[];
  paidAmount: number;
  priceSum: number;
  currency: string;
}

const PaymentProgress = (props: PaymentProgressProps) => {

  const paidAmountPercentageUnclamped = props.paidAmount / props.priceSum * 100;
  const paidAmountPercentage = Math.min(paidAmountPercentageUnclamped, 100);

  return (
    <div className="payment-progress">
      <div className="progress-bar">
        <div className="fill" style={{ width: `${paidAmountPercentage}%` }}></div>
      </div>
      <div className="text">
        <span className="header">
          Plačano:
        </span>
        <span className="value">
          <span>{Localizer.getNumberString(props.paidAmount, 2)} {props.currency}</span> / <span>{Localizer.getNumberString(props.priceSum, 2)} {props.currency}</span>
        </span>
      </div>
    </div>
  )
}

export default PaymentProgress;

import React from 'react';

const Label = ({ className, label, fullLine, children}) => {

  const getClassName = () => {
    let name = "control-row";

    if (fullLine) {
      name += " full-line";
    }

    if (className) {
      name += " " + className;
    }

    return name;
  };

  return (
    <label className={getClassName()}>
      <span className="text">{label}</span>
      {children}
    </label>
  );
};

export default Label;

import React, { useRef } from 'react';
import DatePicker, { ReactDatePicker, registerLocale } from "react-datepicker";
import sl from 'date-fns/locale/sl';

registerLocale('sl', sl);

type DateBoxProps = {
  label: string;
  className?: string | undefined;
  validationFailed?: string | undefined;
  selectsRange?: boolean | undefined;
  fullLine?: boolean | undefined;
  visible?: boolean | undefined;
  value?: Date | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  onChange: (date: Date | (Date | null)[] | null) => void;
}

const DateBox = (props: DateBoxProps) => {

  const getClassName = () => {
    let name = "text-box control-row datebox-focusable";

    let visible = props.visible === undefined ? true : props.visible;
    if (!visible) {
      name += " hidden";
    }

    if (props.fullLine) {
      name += " full-line";
    }

    if (props.className) {
      name += ` ${props.className}`;
    }
    
    return name;
  };

  const datepickerRef = useRef<ReactDatePicker<never>>(null);

  const focusInput = (event: React.MouseEvent<HTMLDivElement, any>) => {
    const isFocusable = (event.target as HTMLSpanElement).classList.contains('datebox-focusable');
    if (isFocusable) {
      datepickerRef?.current?.setFocus();
    }
  };

  const fixDateRangeIfNeeded = () => {
    if (!props.selectsRange) {
      return;
    }

    const bothDatesSet = props.startDate && props.endDate;
    const noneOfDatesSet = !props.startDate && !props.endDate;
    if (bothDatesSet || noneOfDatesSet) {
      return;
    }
    
    let defaultValue: (Date | null)[] = [];

    if (!props.startDate && props.endDate) {
      defaultValue = [props.endDate, props.endDate];
    }
    else if (!props.endDate && props.startDate) {
      defaultValue = [props.startDate, props.startDate];
    }

    if (defaultValue.length > 0) {
      console.log(`date fix, start: ${defaultValue[0]} end: ${defaultValue[1]}`);
    }

    props.onChange(defaultValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {    
    if (event.key === 'Tab') {
      datepickerRef?.current?.setOpen(false);
   }
  };

  const inputClassName = props.validationFailed ? 'inputish validation-failed' : 'inputish';

  const validationTextHtml = props.validationFailed ? (
    <div className="error">
      {props.validationFailed}
    </div>
  ) : null;

  return (
    <div className={getClassName()} onClick={focusInput}>
      <span className="text datebox-focusable">{props.label}</span>
      <DatePicker
      ref={datepickerRef}
      portalId="date-picker-portal"
      className={inputClassName}
      onKeyDown={handleKeyDown}
      selectsRange={props.selectsRange}
      startDate={props.startDate}
      endDate={props.endDate}
      showPopperArrow={false}
      popperPlacement="bottom-end"
      dateFormat="dd.MM.yyyy"
      selected={props.value}
      locale="sl"
      onCalendarClose={fixDateRangeIfNeeded}
      onChange={props.onChange} />
      {validationTextHtml}
    </div>
  );
};

export default DateBox;

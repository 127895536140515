import InvoiceItemListSummary from "../types/InvoiceItemListSummary";
import InvoiceItemSummary from "../types/InvoiceItemSummary";

class InvoiceSummaryProvider {

  static getItemSummary(quantity: number, netoPrice: number | undefined, vatPercentage: number | undefined, discountPercentage: number | undefined) {
    if (quantity === undefined || quantity === null) {
      throw new Error('quantity not set');
    };

    const safeNetoPrice = netoPrice ? netoPrice : 0;
    const safeVatPercentage = vatPercentage ? vatPercentage / 100 : 0;
    const safeDiscountPercentage = discountPercentage ? discountPercentage / 100 : 0;
    const netoPriceSum = quantity * safeNetoPrice;
    const discountPrice = netoPriceSum * safeDiscountPercentage;
    const netoPriceSumWithDiscount = netoPriceSum - discountPrice;
    const vatPrice = netoPriceSumWithDiscount * safeVatPercentage;
    const itemSummary: InvoiceItemSummary = {
      netoPriceSum: netoPriceSum,
      discountPrice: discountPrice,
      netoPriceSumWithDiscount: netoPriceSumWithDiscount,
      vatPrice: vatPrice,
      priceSum: netoPriceSumWithDiscount + vatPrice
    };
    return itemSummary;
  }
  
  static getItemListSummary(items: InvoiceItemSummary[]) {
    const emptySummary: InvoiceItemSummary = {
      vatPrice: 0,
      discountPrice: 0,
      netoPriceSumWithDiscount: 0,
      priceSum: 0,
      netoPriceSum: 0
    };

    const summary = items.length > 0 ? items.reduce((accumulator, item) => {
      return {
        vatPrice: accumulator.vatPrice + item.vatPrice,
        discountPrice: accumulator.discountPrice + item.discountPrice,
        netoPriceSumWithDiscount: accumulator.netoPriceSumWithDiscount + item.netoPriceSumWithDiscount,
        priceSum: accumulator.priceSum + item.priceSum,
        netoPriceSum: accumulator.netoPriceSum + item.netoPriceSum
      };
    }) : emptySummary;

    const itemListSummary: InvoiceItemListSummary = {
      vatPrice: summary.vatPrice,
      discountPrice: summary.discountPrice,
      priceSum: summary.priceSum,
      netoPriceSum: summary.netoPriceSum,
      discountPercentage: summary.discountPrice / summary.netoPriceSum * 100,
      netoPriceSumWithDiscount: summary.netoPriceSumWithDiscount,
      vatPercentage: summary.vatPrice / summary.netoPriceSumWithDiscount * 100
    };
    return itemListSummary;
  }

};

export default InvoiceSummaryProvider;

import axios from 'axios';
import { unescape } from 'html-escaper';
import { useAuth } from 'utils/Auth';
import IbanHelper from 'utils/IbanHelper';

const useGlobalOrganizationSearch = (addLabel) => {
  
  const { createBackendRequest } = useAuth();
  
  let cancellationTokenSource;

  const get = async (queryText) => {
    if (cancellationTokenSource) {
      cancellationTokenSource.cancel('Triggering new search: ' + queryText);
    }

    console.log(`Search for ${queryText}, triggered`);

    cancellationTokenSource = axios.CancelToken.source();

    try {
      const axiosInstance = createBackendRequest();
      const response = await axiosInstance.get('/organizations', {
        cancelToken: cancellationTokenSource.token,
        params: {
          queryText: queryText,
          size: 7
        }
      });
      
      console.log(`Search for ${queryText}, returned ${response.data.length} results.`);
          
      let options = mapToOptions(response.data);
      options.push({
        label: queryText,
        value: queryText,
        addLabel: addLabel
      });
      
      cancellationTokenSource = null;

      return options;
    }
    catch (error) {
      console.log(`Error while searching for organizations: ${error}, query text: ${queryText}`);
    }
  };

  const mapToOptions = (results) => {
    let options = [];
    
    results.forEach(result => {
      const option = {
        label: unescape(result.name),
        detailedLabel: unescape(result.name),
        organization: {
          name: unescape(result.name),
          address: unescape(result.address),
          postOffice: result.postOffice ? result.postOffice.toString() : null,
          city: unescape(result.city),
          country: result.country ? unescape(result.country) : null,
          taxNumber: result.taxNumber ? result.taxNumber.toString() : null,
          countryIssuedId: result.registrationNumber ? result.registrationNumber.toString() : null,
          bankAccount: result.bankAccount ? IbanHelper.tryFormat(unescape(result.bankAccount)) : null,
          taxDebtor: result.taxPayer
        }
      };
      options.push(option);
    });

    return options;
  };
  
  return { get };

};

export default useGlobalOrganizationSearch;

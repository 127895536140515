class InvoiceProgressTextProvider {
  
  static getText(invoiceId: string | null, fromInvoiceId: string | null) {
    if (!invoiceId) {
      if (!fromInvoiceId) {
        return 'Pripravljam nov dokument …';
      }

      return 'Pripravljam dokument na podlagi predračuna …';
    }

    return 'Nalagam dokument …';
  }

}

export default InvoiceProgressTextProvider;

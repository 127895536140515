import React, { useState } from 'react';
import { withRouter, Link, useLocation } from 'react-router-dom';
import TextBox from 'components/forms/TextBox.js';
import LegalFooter from 'components/LegalFooter.js';
import AuthenticationLogo from 'components/AuthenticationLogo.js';
import * as Routes from 'constants/Routes.js';
import Router from 'utils/Router.js';
import { useFirebase } from 'config/Firebase';
import Bugsnag from '@bugsnag/js';

const ResetPassword = () => {

  const [errorText, setErrorText] = useState('');
  const [emailSent, setEmailSent] = useState(null);
  const firebaseContext = useFirebase();
  const query = new URLSearchParams(useLocation().search);
  const defaultEmail = query.get('email');
  const [email, setEmail] = useState(defaultEmail ? defaultEmail : '');
  
  const sendResetPasswordEmail = async (event) => {
    event.preventDefault();
    
    const continueUrl = process.env.REACT_APP_FIREBASE_EMAIL_CONTINUE_URL + Router.route(Routes.login, null, { email: email });
    firebaseContext.firebase.sendPasswordResetEmail(email.trim(), continueUrl).then(() => {
      firebaseContext.firebase.logEvent('reset-password');
      
      setEmailSent(email);
    }).catch((error) => {
      console.log('Reset password error: ' + error);

      var errorCode = error.code;
      console.log('Error code: ' + errorCode);

      switch (errorCode) {
        case 'auth/invalid-email':
          setErrorText('Vnesite veljaven e-mail naslov.');
          break;
        case 'auth/user-not-found':
          setErrorText('Uporabnik z vpisanim e-mail naslovom ne obstaja.');
          break;
        case 'auth/too-many-requests':
          setErrorText('Prekoračili ste dovoljeno število poizkusov. Poskusite znova kasneje.');
          break;
        case 'auth/network-request-failed':
          setErrorText('Preverite vašo internetno povezavo in poskusite ponovno.');
          break;
        default:
          Bugsnag.notify(error);
          setErrorText('Pri ponastavitvi gesla je prišlo do napake: ' + errorCode);
          break;
      }
    });
  };
  
  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const hintHtml = errorText.length > 0 ? null : (
    <div className="bottom-bar">
      Po kliku na gumb Ponastavi geslo boste na vpisan e-mail naslov prejeli povezavo za ponastavitev gesla.
    </div>
  );

  const emailSentHtml = emailSent ? (
    <>
      <div className="bottom-bar">
        Na {emailSent} smo vam poslali povezavo za ponastavitev gesla.
      </div>
      <div className="bottom-bar">
        <Link className="buttonish text-only" to={Router.route(Routes.login, null, { email: emailSent })}>Na prvo stran</Link>
      </div>
    </>
  ) : null;

  const contentHtml = !emailSent ? (
    <>
      <div className="content">
        <TextBox fullLine name="email" type="email" label="E-mail" value={email} onChange={onEmailChange} />
      </div>
      <div className="bottom-bar">
        <button className="buttonish text-only" type="submit">Ponastavi geslo</button>
      </div>
      <div className="bottom-bar error">
        {errorText}
      </div>
      {hintHtml}
      <div className="bottom-bar">
        Še niste registrirani? <Link className="nav-item" to={Router.route(Routes.signup, null, { email: email })}>Registrirajte se</Link>
      </div>
    </>
  ) : null;

  return (
    <form className="page authentication" onSubmit={sendResetPasswordEmail}>
      <AuthenticationLogo />
      <h1>Pozabljeno geslo</h1>
      {emailSentHtml}
      {contentHtml}
      <LegalFooter />
    </form>
  );
};

export default withRouter(ResetPassword);

import React from 'react';
import SuccessorInvoiceData from 'types/SuccessorInvoiceData';
import InvoiceNumberProvider from 'utils/InvoiceNumberProvider';
import RelatedDocument from './RelatedDocument';

type RelatedDocumentListProps = {
  fromInvoiceId?: string | undefined;
  fromInvoiceNumberText?: string | undefined;
  successorInvoices?: SuccessorInvoiceData[] | undefined;
}

const RelatedDocumentList = (props: RelatedDocumentListProps) => {

  const successorInvoices = props.successorInvoices ? props.successorInvoices.map((successorInvoice) => {
    const invoiceNumberText = InvoiceNumberProvider.getInvoiceNumberText(successorInvoice.invoiceNumber, successorInvoice.invoiceNumberYear, successorInvoice.invoiceNumberPrefixText);
    return (
      <RelatedDocument key={successorInvoice.invoiceId} invoiceId={successorInvoice.invoiceId} invoiceNumberText={invoiceNumberText} />
    );
  }) : null;

  const fromInvoice = props.fromInvoiceId && props.fromInvoiceNumberText ? (
    <RelatedDocument invoiceId={props.fromInvoiceId} invoiceNumberText={props.fromInvoiceNumberText} />
  ) : null;

  if (!successorInvoices && !fromInvoice) {
    return null;
  }

  return (
    <div className="invoice-center">
      <div className="line-label">
        Povezani dokumenti
      </div>
      <div className="invoice-attachment-list">
        {successorInvoices}
        {fromInvoice}
      </div>
    </div>
  );

}

export default RelatedDocumentList;

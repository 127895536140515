import { useState } from 'react';
import TaxDescriptionProvider from 'utils/TaxDescriptionProvider';

const useInvoiceItemList = () => {
  
  const [taxDescription, setTaxDescription] = useState('');
  
  const reverseChargeDescriptions = [
    'Obrnjena davčna obveznost – DDV ni obračunan po 1. odstavku. 25. člena ZDDV-1',
    'Obrnjena davčna obveznost – DDV ni obračunan po 76.a členu ZDDV-1'
  ];
  
  const taxDescriptions = [
    TaxDescriptionProvider.noTaxDebtorTaxDescription,
    'DDV skladno s 104. členom ZDDV-1 DDV obračunan od razlike v ceni',
    'Oproščeno DDV po 1. točki 46. člena ZDDV-1',
    'Oproščeno DDV po točki a) prvega odstavka 52. člena ZDDV-1',
    'Oproščeno obračuna DDV po 4.a točki 44. člena ZDDV-1 v povezavi s 5. točko 39. člena PZDDV-1',
    'DDV ni obračunan na osnovi 27.člena ZDDV-1',
    ...reverseChargeDescriptions
  ];

  const isReverseChargeSelected = () => {
    return reverseChargeDescriptions.filter(x => x === taxDescription).length > 0;
  };

  return {
    taxDescriptions,
    taxDescription,
    setTaxDescription,
    isReverseChargeSelected
  };

};

export default useInvoiceItemList;

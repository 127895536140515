import React from 'react';
import InvoiceModel from 'models/InvoiceModel.js';
import Localizer from 'utils/Localizer.js';
import { Page, Text, View, Document, StyleSheet, Image, Svg, Path } from '@react-pdf/renderer';
import InvoicePdfStyle from 'components/documents/invoice/pdf/SimplePdfStyle.js';
import TaxNumberHelper from 'utils/TaxNumberHelper.js';
import InvoiceServiceDateTextFormatter from 'utils/InvoiceServiceDateTextFormatter';
import InvoicePdfHelper from 'components/documents/invoice/InvoicePdfHelper';
import {encodeAsBase64} from'tc-qrcode';

const InvoicePdf = ({ invoice, logoWatermarkVisible, pdfSettings }) => {

  InvoicePdfStyle.registerFonts();
  
  const currency = invoice.metadata.currency;
  const invoiceModel = new InvoiceModel(invoice.data);
  const items = invoiceModel.getItems();
  const summary = invoiceModel.getSummary();
  const hasDiscount = summary.discountPrice > 0;
  const styles = StyleSheet.create(InvoicePdfStyle.getStyle(hasDiscount));
  const invoicePdfHelper = new InvoicePdfHelper(styles);
  const attributionText = invoicePdfHelper.parseHtml(invoice.data.attribution);
  
  const discountSummary = hasDiscount ? (
    <View style={[styles.summaryRow]}>
      <Text style={[styles.summaryValue, styles.value]}>-{Localizer.getNumberString(summary.discountPrice, 2)} {currency}</Text>
      <Text>Popust skupaj:</Text>
    </View>
  ) : null;

  const taxDescription = invoice.itemList.taxDescription.length > 0 ? (
    <Text>{invoice.itemList.taxDescription}</Text>
  ) : null;

  const discountHeader = hasDiscount ? (
    <Text style={[styles.rowEntry, styles.smallEntry]}>Popust</Text>
  ) : null;

  const itemList = items.map((item, index) => {
    const discountRow = hasDiscount ? (
      <Text style={[styles.rowEntry, styles.smallEntry]}>{Localizer.getNumberString(item.discountPercentage, 2, true)} %</Text>
    ) : null;

    const rowBackgroundStyle = index % 2 === 0 ? styles.evenRow : styles.oddRow;

    return (
      <View style={[styles.flexRow, styles.itemListRow, rowBackgroundStyle]} key={item.id}>
        <Text style={[styles.rowEntry, styles.bigEntry]}>{item.name}</Text>
        <Text style={[styles.rowEntry, styles.smallEntry]}>{Localizer.getNumberString(item.quantity, 2, true)}</Text>
        <Text style={[styles.rowEntry, styles.smallEntry]}>{item.unit}</Text>
        <Text style={styles.rowEntry}>{Localizer.getNumberString(item.price, 2)} {currency}</Text>
        <Text style={[styles.rowEntry, styles.smallEntry]}>{Localizer.getNumberString(item.vatPercentage, 2)} %</Text>
        {discountRow}
        <Text style={styles.rowEntry}>{Localizer.getNumberString(item.netoPriceSum - item.discountPrice, 2)} {currency}</Text>
      </View> 
    );
  });

  const getContact = () => {
    if (!invoice.organizationData.email && !invoice.organizationData.phoneNumber) {
      return null;
    }

    if (!invoice.organizationData.email && invoice.organizationData.phoneNumber) {
      return (<Text>T: {invoice.organizationData.phoneNumber}</Text>)
    }

    if (invoice.organizationData.email && !invoice.organizationData.phoneNumber) {
      return (<Text>E: {invoice.organizationData.email}</Text>)
    }

    return (<Text>E: {invoice.organizationData.email}, T: {invoice.organizationData.phoneNumber}</Text>);
  };

  const getSignature = () => {
    if (invoice.data.signatureFileUrl) {
      return (
        <View style={styles.signatureImageHolder}>
          <Image style={styles.signatureImage} src={invoice.data.signatureFileUrl} />
        </View>
      );
    }

    return (<Text style={styles.signatureText}>{invoice.data.signature}</Text>);
  };

  const getQrCode = () => {
    if (!pdfSettings.qrCodeVisible) {
      return null;
    }

    const qrCodeText = invoice.qr.getQrCodeText();
    const qrCodeImageBase64 = encodeAsBase64({
      text: qrCodeText,
      size: 128
    });
    
    return (
      <View style={styles.relative}>
        <Image style={styles.qrCode} src={qrCodeImageBase64} />
      </View>
    )
  }

  const getLogoWatermark = () => {
    if (logoWatermarkVisible) {
      return (
        <View style={[styles.logoWatermark, styles.flexRow]}>
          <Svg style={styles.logoWatermarkSvg}>
            <Path transform="scale(0.1)" fill="black" d="M100,19c-44.74,0-81,36.27-81,81v0c0,11.52,2.42,22.47,6.75,32.39l77.16-77.16l7.8,7.8l-79.59,79.59 c2.27,3.67,4.83,7.13,7.64,10.37l65.32-65.32l8.68,8.68l-65.29,65.29c3.3,2.81,6.81,5.38,10.53,7.64l79.48-79.48l7.65,7.65 l-77.02,77.02c9.79,4.2,20.58,6.54,31.91,6.54h0c44.74,0,81-36.27,81-81V19H100z"></Path>
          </Svg>
          <Text style={styles.logoWatermarkText}>Račun izdelan z aplikacijo Evelope</Text>
        </View>
      );
    }

    return null;
  };

  const serviceDateString = InvoiceServiceDateTextFormatter.getServiceDateString(invoice.metadata.startServiceDate, invoice.metadata.endServiceDate, true, pdfSettings.cultureCode);

  const getVatCategoriesString = () => {
    if (!pdfSettings.vatBreakdownVisible) {
      return null;
    }

    const vatCategories = invoiceModel.getVatCategories();
    return invoicePdfHelper.getVatCategories(vatCategories);
  };

  const vatCategoriesString = getVatCategoriesString();

  return (
    <Document title={invoice.getFileName()}>
      <Page style={styles.page}>
        <View style={styles.flexRow}>
          <View>
            <View style={[styles.organization, styles.company]}>
              <Text>{invoice.organizationData.name}</Text>
              <Text>{invoice.organizationData.address}</Text>
              <Text>{invoice.organizationData.postOffice} {invoice.organizationData.city}</Text>
              <Text>{invoice.organizationData.country}</Text>
              <Text>{TaxNumberHelper.getTaxNumberString(invoice.organizationData.taxNumber)}</Text>
              {getContact()}
            </View>
            <View style={[styles.partner, styles.company]}>
              <Text>{invoice.partnerData.name}</Text>
              <Text>{invoice.partnerData.address}</Text>
              <Text>{invoice.partnerData.postOffice} {invoice.partnerData.city}</Text>
              <Text>{invoice.partnerData.country}</Text>
            </View>
            <Text style={styles.taxNumber}>{TaxNumberHelper.getTaxNumberString(invoice.partnerData.taxNumber)}</Text>
          </View>
          <View style={styles.metadata}>            
            <View style={[styles.flexRow, styles.metadataRowInvoiceNumber]}>
              <Text style={styles.metadataHeader}>{invoice.metadata.invoiceNumberLabelText}</Text>
              <Text style={[styles.metadataInvoiceNumber, styles.metadataValue]}>{invoice.metadata.invoiceNumberText}</Text>
            </View>
            <View style={styles.flexRow}>
              <Text style={styles.metadataHeader}>Datum računa:</Text>
              <Text style={[styles.metadataDateCreated, styles.metadataValue]}>{invoice.metadata.dateCreated.toLocaleDateString(pdfSettings.cultureCode)}</Text>
            </View>
            <View style={styles.flexRow}>
              <Text style={styles.metadataHeader}>Datum storitve:</Text>
              <Text style={[styles.metadataServiceDate, styles.metadataValue]}>{serviceDateString}</Text>
            </View>
            <View style={styles.flexRow}>
              <Text style={styles.metadataHeader}>Rok plačila:</Text>
              <Text style={[styles.metadataLiquidationDate, styles.metadataValue]}>{invoice.metadata.liquidationDate.toLocaleDateString(pdfSettings.cultureCode)}</Text>
            </View>
            {getQrCode()}
          </View>
        </View>
        <View style={styles.itemList}>
          <View style={[styles.flexRow, styles.itemListRow, styles.itemListHeader, styles.borderBottom]}>
            <Text style={[styles.rowEntry, styles.bigEntry]}>Opis storitve</Text>
            <Text style={[styles.rowEntry, styles.smallEntry]}>Količina</Text>
            <Text style={[styles.rowEntry, styles.smallEntry]}>Enota</Text>
            <Text style={styles.rowEntry}>Cena</Text>
            <Text style={[styles.rowEntry, styles.smallEntry]}>Davek</Text>
            {discountHeader}
            <Text style={styles.rowEntry}>Skupaj brez DDV</Text>
          </View>
          {itemList}
        </View>
        <View style={styles.summary}>
          {discountSummary}
          <View style={[styles.summaryRow]}>
            <Text style={[styles.summaryValue, styles.value]}>{Localizer.getNumberString(summary.netoPriceSumWithDiscount, 2)} {currency}</Text>
            <Text>Znesek brez DDV:</Text>
          </View>
          <View style={[styles.summaryRow, styles.borderBottom]}>
            <Text style={[styles.summaryValue, styles.value]}>{Localizer.getNumberString(summary.vatPrice, 2)} {currency}</Text>
            <Text>DDV:</Text>
          </View>
          <View style={[styles.summaryRow]}>
            <Text style={[styles.summaryValue, styles.value]}>{Localizer.getNumberString(summary.priceSum, 2)} {currency}</Text>
            <Text>Znesek z DDV:</Text>
          </View>
        </View>
        {vatCategoriesString}
        <View style={styles.taxDescription}>
          {taxDescription}
        </View>
        <View style={styles.attribution}>
          <Text>
            {attributionText}
          </Text>
        </View>
        <View style={[styles.signature, styles.summary]}>
          {getSignature()}
        </View>
        {getLogoWatermark()}
      </Page>
    </Document>
  );
};

export default InvoicePdf;

import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Routes from 'constants/Routes.js';
import Router from 'utils/Router.js';
import OrganizationForm from 'components/forms/OrganizationForm.js';
import OrganizationSettings from 'components/forms/OrganizationSettings.js';
import OrganizationSelectBox from 'components/forms/OrganizationSelectBox.js';
import Label from 'components/forms/Label.js';
import LegalFooter from 'components/LegalFooter.js';
import useValidation from 'hooks/useValidation.js';
import useCollection from 'hooks/useCollection.js';
import useOrganization from 'hooks/useOrganization';
import { useAuth } from 'utils/Auth';
import Bugsnag from '@bugsnag/js';
import DisclaimerText from 'components/forms/DisclaimerText';

const Organization = ({history}) => {

  const { register, errors, handleSubmit } = useForm();
  const validationErrors = useValidation(errors);
  const organization = useOrganization();

  const { currentUser, setUserData, setOrganizationData } = useAuth();
  const usersCollection = useCollection('users');
  const [userSaving, setUserSaving] = useState(false);
  
  const saveUserData = useCallback(async (organizationId, organizationData) => {
    const userData = {
      organizationId: organizationId,
      organizationName: organizationData.name,
      userAgentUsedForSignUp: navigator.userAgent
    };
    await usersCollection.addWithId(currentUser.uid, userData);
    setUserData(userData);
    setOrganizationData(organizationData);
  }, [ currentUser.uid, usersCollection, setUserData, setOrganizationData ]);

  const saveOrganization = useCallback(async (formData) => {
    const savedOrganizationInfo = await organization.save(formData);
    if (!savedOrganizationInfo) {
      return;
    }
    
    try {
      setUserSaving(true);

      await saveUserData(savedOrganizationInfo.id, savedOrganizationInfo.data);

      const route = Router.route(Routes.home);
      history.push(route);
    }
    catch (error) {
      setUserSaving(false);
      Bugsnag.notify(error);
      console.log('Error while saving user data: ' + error);
    }
  }, [ organization, saveUserData, history ]);

  const onOrganizationNameSelected = (selectedOption) => {
    // Workaround for issue #136. Looks like AsyncSelect triggers onChange handler when pressing backspace on the empty text box.
    if (!selectedOption) {
      return;
    }

    if (selectedOption.organization) {
      organization.setOrganizationData(selectedOption.organization);
    } else {
      const data = {};
      data.name = selectedOption.label;
      organization.setOrganizationData(data);
    }
  };

  const selectDifferentOrganization = () => {
    organization.setOrganizationData(null);
  };

  const nameComponent = !organization.organizationData ? (
    <>
      <Label label="" fullLine className="full">
        <OrganizationSelectBox
          name="organization"
          value={organization.organizationData ? organization.organizationData.id : null}
          text={organization.organizationData ? organization.organizationData.name : null}
          register={register}
          onChange={onOrganizationNameSelected}
          placeholder="Naziv ..."
          searchGlobalOrganizations
          autoFocus
          addText="Ročno vnesi podatke o podjetju ..." />
      </Label>
      <DisclaimerText>
        Za zaključek registracije vnesite naziv ali davčno št. podjetja za katerega želite izdajati račune.
      </DisclaimerText>
    </>
  ) : null;

  const detailsComponent = organization.organizationData ? (
    <form onSubmit={handleSubmit(saveOrganization)}>
      <div className="split-table">
        <OrganizationForm data={organization.organizationData} detailsRequired hasBankAccount hasTaxDebtor autoFocus errors={errors} register={register} />
      </div>
      <OrganizationSettings data={organization.organizationData} errors={errors} register={register} />
      <div className="button-bottom-bar error">
        {validationErrors}
      </div>
      <div className="button-bottom-bar r-align">
        <span className="link action l-float hide-on-mobile" onClick={selectDifferentOrganization}>Izberi drugo organizacijo ...</span>
        <button className="buttonish text-only" disabled={organization.saving || userSaving} type="submit">Zaključi registracijo</button>
      </div>
    </form>
  ) : null;

  const getTitle = () => {
    if (organization.organizationData) {
      return 'Vnesite podatke o vašem podjetju oz. organizaciji';
    }

    return 'Vnesite naziv podjetja oz. organizacije';
  };

  return (
    <div className="page authentication">
      <h1>{getTitle()}</h1>
      <div className="panel">
        {nameComponent}
        {detailsComponent}
      </div>
      <LegalFooter />
    </div>
  );
};

export default withRouter(Organization);

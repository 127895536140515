import { useReducer, useState } from 'react';
import InvoiceItem from 'types/firestore_schema/InvoiceItem';
import InvoiceItemListSummary from 'types/InvoiceItemListSummary';
import InvoiceItemModel from 'types/InvoiceItemModel';
import InvoiceSummaryProvider from "utils/InvoiceSummaryProvider";

const useInvoiceItemListState = () => {
  
  const [vatPercentageDefault, setVatPercentageDefault] = useState<number | null>(null);

  type State = {
    models: InvoiceItemModel[],
    summary: InvoiceItemListSummary
  }

  enum ActionType {
    Init,
    Add,
    Remove,
    SetName,
    SetUnit,
    SetQuantity,
    SetPrice,
    SetVatPercentage,
    SetDiscountPercentage,
    UpdateSummary
  }

  type InitAction = {
    type: ActionType,
    existingItems: InvoiceItem[]
  }

  type AddAction = {
    type: ActionType
  }

  type RemoveAction = {
    type: ActionType,
    id: string
  }

  type SetAction = {
    type: ActionType,
    id: string,
    value: string | number
  }

  type UpdateSummaryAction = {
    type: ActionType
  }
  
  const isAddButtonEnabled = () => {
    const lastItem = state.models[state.models.length - 1];
    if (!lastItem) {
      throw new Error('no items - there should always be at least 1');
    }

    const enabled = lastItem.item.name.trim().length > 0;
    return enabled;
  };

  const addItem = () => {
    const addAction: AddAction = {
      type: ActionType.Add
    };
    dispatch(addAction);
  };

  const removeItem = (model: InvoiceItemModel) => {
    const removeAction: RemoveAction = {
      type: ActionType.Remove,
      id: model.item.id
    };
    dispatch(removeAction);

    dispatchUpdateSummaryAction();
  };

  const init = (existingItems: InvoiceItem[], vatPercentageDefault: number) => {
    setVatPercentageDefault(vatPercentageDefault);
    const initAction: InitAction = {
      type: ActionType.Init,
      existingItems: existingItems
    };
    dispatch(initAction);
  };

  const changeItemName = (model: InvoiceItemModel, value: string) => {
    dispatchSetAction(ActionType.SetName, model, value);
  };

  const changeItemUnit = (model: InvoiceItemModel, value: string) => {
    dispatchSetAction(ActionType.SetUnit, model, value);
  };

  const changeItemQuantity = (model: InvoiceItemModel, value: number) => {
    dispatchSetAction(ActionType.SetQuantity, model, value);
    dispatchUpdateSummaryAction();
  };

  const changeItemPrice = (model: InvoiceItemModel, value: number) => {
    dispatchSetAction(ActionType.SetPrice, model, value);
    dispatchUpdateSummaryAction();
  };

  const changeItemVatPercentage = (model: InvoiceItemModel, value: number) => {
    dispatchSetAction(ActionType.SetVatPercentage, model, value);
    dispatchUpdateSummaryAction();
  };

  const changeItemDiscountPercentage = (model: InvoiceItemModel, value: number) => {
    dispatchSetAction(ActionType.SetDiscountPercentage, model, value);
    dispatchUpdateSummaryAction();
  };

  const dispatchSetAction = (actionType: ActionType, model: InvoiceItemModel, value: string | number) => {
    const setAction: SetAction = {
      type: actionType,
      id: model.item.id,
      value: value
    };
    dispatch(setAction);
  };

  const dispatchUpdateSummaryAction = () => {
    const updateSummaryAction: UpdateSummaryAction = {
      type: ActionType.UpdateSummary
    };
    dispatch(updateSummaryAction);
  };
    
  const createNewItem = (autoFocus: boolean) => {
    if (vatPercentageDefault === null) {
      throw new Error('init not done yet');
    }

    const item: InvoiceItem = {
      id: Math.random().toString(36).substr(2, 9),
      name: '',
      quantity: 1,
      unit: 'kos',
      price: 0,
      vatPercentage: vatPercentageDefault!,
      discountPercentage: 0,
    };
    const itemSummary = InvoiceSummaryProvider.getItemSummary(item.quantity, item.price, item.vatPercentage, item.discountPercentage);
    const model: InvoiceItemModel = {
      item: item,
      isNew: true,
      shouldAutoFocus: autoFocus,
      quantityDefault: 1,
      priceDefault: 0,
      vatPercentageDefault: vatPercentageDefault!,
      discountPercentageDefault: 0,
      summary: itemSummary
    };
    return model;
  };

  const reducer = (state: State, action: AddAction | RemoveAction | SetAction | UpdateSummaryAction) => {
    switch (action.type) {
      case ActionType.Init: {
        if (vatPercentageDefault === null) {
          throw new Error('init not done yet');
        }
        
        const initAction = action as InitAction;
        // When viewing existing invoice, return existing invoice data with calculated price sum
        if (initAction.existingItems.length > 0) {
          const models = initAction.existingItems.map((item: InvoiceItem, index: number) => {
            const itemSummary = InvoiceSummaryProvider.getItemSummary(item.quantity, item.price, item.vatPercentage, item.discountPercentage);
            const model: InvoiceItemModel = {
              item: item,
              isNew: false,
              shouldAutoFocus: false,
              quantityDefault: 1,
              priceDefault: 0,
              vatPercentageDefault: vatPercentageDefault!,
              discountPercentageDefault: 0,
              summary: itemSummary
            }
            return model;
          });

          const summary = InvoiceSummaryProvider.getItemListSummary(models.map(x => x.summary));

          const state: State = {
            models: models,
            summary: summary
          };
          return state;
        }
        
        const emptySummary = InvoiceSummaryProvider.getItemListSummary([]);
        const state: State = {
          models: [
            createNewItem(false)
          ],
          summary: emptySummary
        };
        return state;
      }
      case ActionType.Add: {        
        const newState = {
          ...state
        };

        const newItem = createNewItem(true);
        newState.models = [
          ...newState.models,
          newItem];

        return newState;
      }
      case ActionType.Remove: {        
        const removeAction = action as RemoveAction;
        const newState = {
          ...state
        };
        
        newState.models = newState.models.filter(model => {
          return model.item.id !== removeAction.id;
        });
        
        return newState;
      }
      case ActionType.SetName:
      case ActionType.SetUnit:
      case ActionType.SetQuantity:
      case ActionType.SetPrice:
      case ActionType.SetVatPercentage:
      case ActionType.SetDiscountPercentage: {
        const newState = {
          ...state
        };
        
        newState.models = newState.models.map(model => {
          const setAction = action as SetAction;
          if (model.item.id === setAction.id) {
            switch (action.type) {
              case ActionType.SetName:
                model.item.name = setAction.value as string;
                break;
              case ActionType.SetUnit:
                model.item.unit = setAction.value as string;
                break;
              case ActionType.SetQuantity:
                model.item.quantity = setAction.value as number;
                break;
              case ActionType.SetPrice:
                model.item.price = setAction.value as number;
                break;
              case ActionType.SetVatPercentage:
                model.item.vatPercentage = setAction.value as number;
                break;
              case ActionType.SetDiscountPercentage:
                model.item.discountPercentage = setAction.value as number;
                break;
              default:
                throw new Error('not implemented');
            }
          }
          
          return {
            ...model
          };
        });

        return newState;
      }
      case ActionType.UpdateSummary: {
        const newState = {
          ...state
        };

        newState.models = newState.models.map(model => {
          const itemSummary = InvoiceSummaryProvider.getItemSummary(model.item.quantity, model.item.price, model.item.vatPercentage, model.item.discountPercentage);
          return {
            ...model,
            summary: itemSummary
          };
        });

        newState.summary = InvoiceSummaryProvider.getItemListSummary(newState.models.map(x => x.summary));

        return newState;
      }
      default:
        throw new Error('not implemented');
    }
  }

  const emptySummary = InvoiceSummaryProvider.getItemListSummary([]);
  const initialState: State = {
    models: [],
    summary: emptySummary,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getItems = () => {
    return state.models.map(x => x.item);
  };

  return {
    models: state.models,
    summary: state.summary,
    init,
    getItems,
    isAddButtonEnabled,
    addItem,
    removeItem,
    changeItemName,
    changeItemUnit,
    changeItemQuantity,
    changeItemPrice,
    changeItemVatPercentage,
    changeItemDiscountPercentage
  };

};

export default useInvoiceItemListState;

import CreateButton from 'components/CreateButton';
import EmptyView from 'components/EmptyView';
import MiniDropdownBox, { MiniDropdownBoxOption } from 'components/forms/MiniDropdownBox';
import List from 'components/List';
import { useFirebase } from 'config/Firebase';
import useLiveCollection from 'hooks/useLiveCollection';
import SubscriptionsProvider from 'providers/SubscriptionsProvider';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ListDataSource from 'types/ListDataSource';
import SubscriptionData from 'types/SubscriptionData';
import SubscriptionType from 'types/SubscriptionType';
import { useAuth } from 'utils/Auth';
import SubscriptionTypeLocalizer from 'utils/SubscriptionTypeLocalizer';
import SubscriptionTypeProvider from 'utils/SubscriptionTypeProvider';

type OrganizationSubscriptionsParams = {
  
  organizationId: string;

};

const OrganizationSubscriptions = ({ match }: RouteComponentProps<OrganizationSubscriptionsParams>) => {

  const firebaseContext = useFirebase();
  const { organizationData } = useAuth();
  const db = firebaseContext!.firebase.db();
  const subscriptionsProvider = new SubscriptionsProvider(db, match.params.organizationId);
  const { snapshot, pager } = useLiveCollection('organizations', 'activatedAt', undefined, undefined, undefined, false, match.params.organizationId, 'subscriptions');
  
  if (!snapshot) {
    return <></>
  }

  const activateSubscription = (subscriptionType: SubscriptionType) => {
    subscriptionsProvider.activateSubscription(subscriptionType.id);
  }

  const deactivateSubscription = (subscriptionId: string) => {
    subscriptionsProvider.deactivateSubscription(subscriptionId);
  };

  const handleSelectedOptionChange = (oldSelectedOption: MiniDropdownBoxOption, newSelectedOption: MiniDropdownBoxOption) => {
    const subscriptionId = newSelectedOption.value;
    deactivateSubscription(subscriptionId);
  };

  const getActiveBadge = (subscription: SubscriptionData) => {
    const activatedOption: MiniDropdownBoxOption = {
      value: subscription.id!,
      text: 'Active',
      className: 'green'
    };
    
    const deactivatedOption: MiniDropdownBoxOption = {
      value: subscription.id!,
      text: 'Deactivated',
      className: 'red'
    };

    const selectedOption = subscription.active ? activatedOption : deactivatedOption;
    
    let options = [
      activatedOption
    ];

    if (subscription.active) {
      options.push(deactivatedOption);
    }

    return (
      <MiniDropdownBox selectedOption={selectedOption} options={options} onSelectedOptionChanged={handleSelectedOptionChange} />
    );
  }
  
  const rows = snapshot.map(document => {
    const rawData = document.data();
    const subscription = subscriptionsProvider.getSubscription(document.id, rawData);
    return {
      id: subscription.id,
      type: SubscriptionTypeLocalizer.getName(subscription.type.id),
      activatedAt: subscription.activatedAt.toLocaleString(organizationData.cultureCode),
      deactivatedAt: subscription.deactivatedAt ? subscription.deactivatedAt.toLocaleString(organizationData.cultureCode) : 'N/A',
      activeBadge: getActiveBadge(subscription)
    };
  });

  const dataSource: ListDataSource = {
    columns: [
      {
        id: 'type',
        header: 'Subscription type',
        className: 'main'
      },
      {
        id: 'activatedAt',
        header: 'Activated',
      },
      {
        id: 'deactivatedAt',
        header: 'Deactivated'
      },
      {
        id: 'activeBadge',
        header: '',
        className: 'short visible-overflow'
      }
    ],
    rows: rows
  };
  
  const defaultSubscription = SubscriptionTypeProvider.getDefault();
  const secondaryActions = SubscriptionTypeProvider.getAll().filter(subscriptionType => subscriptionType !== defaultSubscription).map((subscriptionType) => {
    return {
      onClick: () => activateSubscription(subscriptionType),
      text: SubscriptionTypeLocalizer.getCreateText(subscriptionType.id)
    };
  });

  const createButton = (
    <CreateButton onClick={() => activateSubscription(SubscriptionTypeProvider.getDefault())} secondaryActions={secondaryActions}>{SubscriptionTypeLocalizer.getCreateText(defaultSubscription.id)}</CreateButton>
  );
  
  const emptyView = (
    <EmptyView text="No subscriptions" button={createButton} />
  );
  
  return (
    <div className="page">
      {createButton}
      <h1>Subscriptions</h1>
      <div className="panel">
        <List dataSource={dataSource} pager={pager} emptyView={emptyView} />
      </div>
    </div>
  );

};

export default withRouter(OrganizationSubscriptions);

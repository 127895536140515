import React from 'react';
import { withRouter } from 'react-router-dom';
import ErrorView from 'components/ErrorView.js';

const NotFound = () => {
 
  return (
    <ErrorView errorId="not-found" />
  );
};

export default withRouter(NotFound);

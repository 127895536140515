export const signup = '/signup';
export const login = '/login';
export const resetPassword = '/login/reset';
export const home = '/';
export const selectOrganization = '/organizations/select';
export const organizations = '/organizations/{organizationId}';
export const organizationSubscriptions = '/organizations/{organizationId}/subscriptions';
export const documents = '/documents/{documentId}';
export const partners = '/partners/{partnerId}';
export const settings = '/settings';
export const error = '/error/{errorId}';

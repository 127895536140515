
import { useFirebase } from "config/Firebase";
import SubscriptionsProvider from "providers/SubscriptionsProvider";
import { useEffect, useState } from "react";
import SubscriptionInfoType from "types/SubscriptionInfoType";
import { useAuth } from "utils/Auth";
import useNumberOfInvoicesCreated from "./useNumberOfInvoicesCreated";

const useSubscriptionInfo = () => {

  const [subscriptionInfoType, setSubscriptionInfoType] = useState<SubscriptionInfoType | undefined>();
  const firebaseContext = useFirebase();
  const db = firebaseContext!.firebase.db();
  const { numberOfInvoicesCreatedThisMonth } = useNumberOfInvoicesCreated();
  const { userData } = useAuth();
  
  useEffect(() => {
    if (numberOfInvoicesCreatedThisMonth && userData) {      
      const getActiveSubscription = async () => {
        const provider = new SubscriptionsProvider(db, userData.organizationId!);
        const activeSubscriptions = await provider.getActiveSubscriptions();
        const activeSubscription = activeSubscriptions.length > 0 ? activeSubscriptions[0] : null;

        const getInfoType = () => {
          if (activeSubscription && activeSubscription.needsPaymentInfoUpdate) {
            return SubscriptionInfoType.NeedsPaymentInfoUpdate;
          }

          const maximumNumberOfInvoicesPerMonth = activeSubscription ? activeSubscription.type.maximumNumberOfInvoicesPerMonth : 2;
          if (numberOfInvoicesCreatedThisMonth >= maximumNumberOfInvoicesPerMonth) {
            return SubscriptionInfoType.InvoicesCreatedLimitReached;
          }

          return SubscriptionInfoType.None;
        }

        const infoType = getInfoType();
        setSubscriptionInfoType(infoType);
      };

      getActiveSubscription();
    }
  }, [db, userData, numberOfInvoicesCreatedThisMonth]);

  return {
    subscriptionInfoType
  }

}

export default useSubscriptionInfo;

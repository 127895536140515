import React from 'react';
import SelectBox from 'components/forms/SelectBox.js';
import DateAndTextOption from 'components/documents/invoice/DateAndTextOption.js';

type InvoiceLiquidationDateProps = {
  value: number;
  visible: boolean;
  dateCreated: Date;
  onChange: (selectedOption: any) => void;
}

const InvoiceLiquidationDate = (props: InvoiceLiquidationDateProps) => {

  const getDateAfterDays = (daysToAdd: number) => {
    const newDate = new Date(props.dateCreated);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return newDate;
  };

  const getDaysOption = (days: number) => {
    const daysOption = getOption(days, days + ' dni');
    const date = getDateAfterDays(days);
    return {
      ...daysOption,
      date: date.toLocaleDateString()
    }
  };

  const getOption = (value: number | null, label: string) => {
    return {
      value: value,
      label: label
    };
  };

  const options = [
    getDaysOption(15),
    getDaysOption(30),
    getDaysOption(45),
    getDaysOption(60),
    getOption(null, 'Poljubno ...')
  ];

  const selectedOption = getDaysOption(props.value);

  return (
    <SelectBox
    label="Rok plačila:"
    visible={props.visible}
    value={selectedOption}
    options={options}
    onChange={props.onChange}
    components={{ Option: DateAndTextOption }}
    className={undefined}
    fullLine={undefined}
    isClearable={undefined}
    style={undefined}
    defaultMenuIsOpen={undefined} />
  );
};

export default InvoiceLiquidationDate;

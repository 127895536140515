import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Routes from 'constants/Routes.js';
import Router from 'utils/Router.js';
import SignOutButton from 'components/SignOutButton.js';
import { ReactComponent as ListIcon } from 'icons/bills.svg';
import { ReactComponent as SettingsIcon } from 'icons/settings.svg';
import { ReactComponent as CustomersIcon } from 'icons/customers.svg';
import { ReactComponent as LogoIcon } from 'icons/logo.svg';
import NavigationItem from 'components/NavigationItem.js';
import { useAuth } from 'utils/Auth';

const Navigation = ({ selection }) => {
  const { userData } = useAuth();
  const location = useLocation()
  const currentOrganizationId = userData ? userData.organizationId : null;
  const [navigationVisible, setNavigationVisible] = useState(false);
  const navigationClassName = navigationVisible ? '' : 'hide-on-mobile';
  const hamburgerClassName = navigationVisible ? 'hamburger open' : 'hamburger';
  const mobileHeaderClassName = navigationVisible ? 'mobile-header open' : 'mobile-header';

  const toggleNavigation = () => {
    setNavigationVisible(!navigationVisible);
  };

  const hideNavigation = () => {
    setNavigationVisible(false);
  };

  return (
    <>
      <div className={mobileHeaderClassName}>       
        <div className="menu-element" onClick={toggleNavigation}>
          <div className={hamburgerClassName}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <Link className="menu-element" to={location.pathname} tabIndex="-1">
          <h2>{selection}</h2>
        </Link>
        <div className="menu-element" />
      </div>
      <nav className={navigationClassName}>
        <ol className="top">
          <li className="hide-on-mobile">
            <Link className="nav-item logo" to={Router.route(Routes.home)} tabIndex="-1">
              <LogoIcon />
            </Link>
          </li>
          <li>
            <NavigationItem title="Računi" to={Router.route(Routes.home)} selection={selection} onClick={hideNavigation}>
              <ListIcon />
            </NavigationItem>
          </li>
          <li>
            <NavigationItem title="Partnerji" to={Router.route(Routes.partners)} selection={selection} onClick={hideNavigation}>
              <CustomersIcon />
            </NavigationItem>
          </li>
        </ol>
        <ol className="bottom">
          <li>
            <NavigationItem title="Nastavitve" to={Router.route(Routes.organizations, { organizationId: currentOrganizationId })} selection={selection} onClick={hideNavigation}>
              <SettingsIcon />
            </NavigationItem>
          </li>
          {/* <li>
            <NavigationItem title="Organizacije" to={Router.route(Routes.organizations)}>
              <SettingsIcon />
            </NavigationItem>
          </li>
          <li>
            <NavigationItem title="Nastavitve" to={Router.route(Routes.settings)}>
              <SettingsIcon />
            </NavigationItem>
          </li> */}
          <li>
            <SignOutButton />
          </li>
        </ol>
      </nav>
    </>
  );
};

export default Navigation;

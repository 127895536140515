import InvoiceStatus from 'types/InvoiceStatus';
import PaymentReferenceProvider from 'utils/PaymentReferenceProvider';

export default class InvoiceAttributionProvider {

  static getAttributionText(attribution: string, invoiceNumber: number, invoiceNumberYear: number, bankAccount: string) {
    const paymentReference = PaymentReferenceProvider.getPaymentReference(invoiceNumber, invoiceNumberYear);
    return attribution.replace("{št. računa}", paymentReference).replace("{IBAN}", bankAccount);
  };

  static getPotentialAttributionText(invoiceStatus: InvoiceStatus, fromInvoiceNumberText: string) {
    switch (invoiceStatus) {
      case 'Paid':
        return `Račun že plačan po predračunu št. ${fromInvoiceNumberText}`;
      default:
        return null;
    }
  };

}

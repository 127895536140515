import React from 'react';

const DateAndTextOption = (props) => {
  const {
    innerProps,
    isDisabled,
    getStyles,
    data} = props;

  if (isDisabled) {
    return null;
  }

  const defaultOptionStyle = getStyles('option', props);
  const optionStyle = {
    ...defaultOptionStyle,
    padding: '7px 10px'
  };

  if (!data.date) {
    return (
      <div
      {...innerProps}
      style={optionStyle}>
        {data.label}
      </div>
    );
  }

  return (
    <div
    {...innerProps}
    style={optionStyle}>
      <div className="day bold">{data.label}</div>
      <div className="date smallest">{data.date}</div>
    </div>
  );
};

export default DateAndTextOption;

import React from 'react';

type TabControlProps = {
  selectedIndex: number;
  options: string[];
  onSelectedIndexChanged: (newSelectedIndex: number) => void;
  className?: string | undefined;
}

const TabControl = (props: TabControlProps) => {

  const options = props.options.map((option, index) => {
    const isOptionSelected = index === props.selectedIndex;
    const optionClassName = isOptionSelected ? "selected" : "";
    return (
      <li key={option} className={optionClassName} onClick={() => props.onSelectedIndexChanged(index)}>{option}</li>
    );
  });

  const getClassName = () => {
    let className = "tab-control noselect";

    if (props.className) {
      className += " " + props.className;
    }

    return className;
  }

  return (
    <div className="tab-center">
      <ol className={getClassName()}>
        {options}
      </ol>
    </div>
  );
  
}

export default TabControl;

import React, { ChangeEvent } from 'react';
import NumberFormat from 'react-number-format';
import Localizer from 'utils/Localizer';

type NumberBoxProps = {
  className?: string | undefined;
  label: string,
  value: number,
  autoFocus?: boolean | undefined,
  decimalScale?: number | undefined,
  fixedDecimalScale?: boolean | undefined,
  onChange: (value: number) => void;
  validationFailed?: boolean | undefined;
  fullLine?: boolean | undefined;
  info?: any;
  visible?: boolean | undefined;
}

const NumberBox = (props: NumberBoxProps) => {
  const decimalScale = props.decimalScale !== undefined ? props.decimalScale : 0;

  const getClassName = () => {
    let name = "text-box control-row";

    if (props.visible === false) {
      name += " hidden";
    }

    if (props.fullLine) {
      name += " full-line";
    }

    if (props.className) {
      name += " " + props.className;
    }

    return name;
  };

  const handleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event);
    props.onChange(value);
  };

  // TODO: copy pasted from invoice item list
  const parseFloat = (event: React.ChangeEvent<HTMLInputElement>) => {
    return event.target.value.length > 0 ? Localizer.parseFloat(event.target.value) : 0;
  };

  const selectInputText = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => {
    event.target.select();
  };

  return (
    <label className={getClassName()}>
      <span className="text">{props.label}</span>
      <div className="input-holder">
        <div className="flex-wrapper">
          <NumberFormat
          className={props.validationFailed ? 'inputish validation-failed' : 'inputish'}
          value={props.value}
          autoFocus={props.autoFocus}
          decimalScale={decimalScale}
          fixedDecimalScale={props.fixedDecimalScale}
          thousandSeparator={Localizer.getThousandsSeparator()}
          decimalSeparator={Localizer.getDecimalSeparator()}
          onFocus={selectInputText}
          onChange={handleChanged} />
          {props.info}
        </div>
      </div>
    </label>
  );
};

export default NumberBox;

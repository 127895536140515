import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import List from 'components/List';
import useLiveCollection from 'hooks/useLiveCollection';
import CreateButton from 'components/CreateButton';
import EmptyView from 'components/EmptyView';
import PartnerEditModal from 'components/modals/PartnerEditModal.js';
import SortDirection from 'types/SortDirection';
import SortOption from 'types/SortOption';
import ListDataSource from 'types/ListDataSource';

const Partners = () => {

  const { snapshot, pager, setOrderByField, setOrderByDirection } = useLiveCollection('partners', 'name', SortDirection.Ascending);
  const [partnerModalOpen, setPartnerModalOpen] = useState(false);
  const [editPartnerId, setEditPartnerId] = useState<string | null>(null);
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>({ fieldName: 'name', direction: SortDirection.Ascending });

  if (!snapshot) {    
    return <></>
  }

  const closePartnerModal = (partner: any) => {
    setEditPartnerId(null);
    setPartnerModalOpen(false);
  };

  const openPartnerModal = () => {
    setPartnerModalOpen(true);
  };

  const editPartner = (partnerId: string) => {
    setPartnerModalOpen(true);
    setEditPartnerId(partnerId);
  };
  
  let rows: any[] = [];
  snapshot.forEach(document => {
    const data = document.data();
    const partner = {
      id: document.id,
      onClick: () => editPartner(document.id),
      name: data.name,
      address: data.address,
      postOffice: data.postOffice,
      city: data.city,
      taxNumber: data.taxNumber
    };
    rows.push(partner);
  });

  const dataSource: ListDataSource = {
    columns: [
      {
        id: 'name',
        header: 'Partner',
        className: 'main',
        sortFieldName: 'name'
      },
      {
        id: 'address',
        header: 'Naslov',
        sortFieldName: 'address'
      },
      {
        id: 'postOffice',
        header: 'Pošta',
        className: 'short',
        sortFieldName: 'postOffice'
      },
      {
        id: 'city',
        header: 'Mesto',
        sortFieldName: 'city'
      },
      {
        id: 'taxNumber',
        header: 'Davčna številka',
        sortFieldName: 'taxNumber'
      }
    ],
    rows: rows
  };

  const createButton = (
    <CreateButton onClick={openPartnerModal}>Dodaj partnerja</CreateButton>
  );
  
  const emptyView = (
    <EmptyView text="Trenutno še ne sodelujete z nobenim partnerjem." button={createButton} />
  );

  const handleSortChange = (sortOption: SortOption) => {
    setOrderByField(sortOption.fieldName);
    setOrderByDirection(sortOption.direction);
    pager.getFirst();

    setSelectedSortOption(sortOption);
  };

  return (
    <div className="page partners">
      {createButton}
      <h1 className="hide-on-mobile">Partnerji</h1>      
      <div className="panel">
        <List dataSource={dataSource} pager={pager} emptyView={emptyView} onSortChange={handleSortChange} sortOption={selectedSortOption} />
      </div>
      <PartnerEditModal
        open={partnerModalOpen}
        partnerId={editPartnerId}
        editCollection
        onClose={closePartnerModal}
        onSave={closePartnerModal}
        data={undefined} />
    </div>
  );
};

export default withRouter(Partners);

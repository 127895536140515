import React from 'react';
import { Link } from 'react-router-dom';

const NavigationItem = (props) => {
  const selected = props.selection === props.title;
  const className = selected ? "nav-item selected" : "nav-item";

  const content = (
    <>
      <div className="icon">{props.children}</div>
      <div className="title">{props.title}</div>
    </>
  );

  if (!props.to) {
    return (
      <div className={className} onClick={props.onClick}>
        {content}
      </div>
    );
  }

  return (
    <Link className={className} to={props.to} tabIndex="-1" onClick={props.onClick}>
      {content}
    </Link>
  );
};

export default NavigationItem;

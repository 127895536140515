import { useFirebase } from "config/Firebase";
import { useEffect, useState } from "react";

const useBearerToken = () => {

  const firebaseContext = useFirebase();
  const [bearerToken, setBearerToken] = useState<string | null>(null);

  useEffect(() => {
    
    const onIdTokenChanged = (user: firebase.default.User | null) => {
      requestNewTokenIfNeeded(user, true);
    }

    const requestNewTokenIfNeeded = (user: firebase.default.User | null, forceRefresh: boolean) => {
      if (user) {
        if (!bearerToken || forceRefresh) {
        // todo: cancel prommise when user logs out
          firebaseContext!.firebase.getIdToken().then((idToken) => {
            setBearerToken(idToken);
          });
        }
      } else {
        setBearerToken(null);
      }
    }
    
    const onIdTokenChangedUnsubscribe = firebaseContext!.firebase.onIdTokenChanged(onIdTokenChanged);

    return () => {
      onIdTokenChangedUnsubscribe();
    };
    
  }, [firebaseContext, bearerToken]);

  return bearerToken;

}

export default useBearerToken;

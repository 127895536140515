import React, { useState } from 'react';
import * as ControlPanelRoutes from 'constants/ControlPanelRoutes';
import { withRouter } from 'react-router-dom';
import Router from 'utils/Router';
import List from 'components/List';
import EmptyView from 'components/EmptyView';
import useLiveCollection from 'hooks/useLiveCollection';
import OrganizationProvider from 'providers/OrganizationProvider';
import SortDirection from 'types/SortDirection';
import SortOption from 'types/SortOption';

const Organizations = () => {

  const { snapshot, pager, setOrderByField, setOrderByDirection } = useLiveCollection('organizations', 'name', SortDirection.Ascending, 20, undefined, false);
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>({ fieldName: 'name', direction: SortDirection.Ascending });
  
  if (!snapshot) {    
    return <></>
  }

  const organizationProvider = new OrganizationProvider();
  
  let rows: any[] = [];
  snapshot.forEach(document => {
    const rawData = document.data();
    const data = organizationProvider.get(rawData);
    const organization = {
      id: document.id,
      href: Router.route(ControlPanelRoutes.organizations, { organizationId: document.id }),
      name: data.name,
      address: data.address,
      postOffice: data.postOffice,
      city: data.city,
      country: data.country
    };
    rows.push(organization);
  });

  const dataSource = {
    columns: [
      {
        id: 'name',
        header: 'Naziv organizacije',
        className: 'main',
        sortFieldName: 'name'
      },
      {
        id: 'address',
        header: 'Naslov',
        sortFieldName: 'address'
      },
      {
        id: 'postOffice',
        header: 'Pošta',
        className: 'short',
        sortFieldName: 'postOffice'
      },
      {
        id: 'city',
        header: 'Mesto',
        sortFieldName: 'city'
      },
      {
        id: 'country',
        header: 'Država',
        sortFieldName: 'country'
      }
    ],
    rows: rows
  };
  
  const emptyView = (
    <EmptyView text="V bazi ni še nobene organizacije." />
  );
  
  const handleSortChange = (sortOption: SortOption) => {
    setOrderByField(sortOption.fieldName);
    setOrderByDirection(sortOption.direction);
    pager.getFirst();

    setSelectedSortOption(sortOption);
  };

  return (
    <div className="page">
    <div className="dummy-top"></div>
      <h1>Organizacije</h1>
      <div className="panel">
        <List dataSource={dataSource} pager={pager} emptyView={emptyView} onSortChange={handleSortChange} sortOption={selectedSortOption} />
      </div>
    </div>
  );

};

export default withRouter(Organizations);

import InvoiceSummaryProvider from '../utils/InvoiceSummaryProvider';

export default class InvoiceModel {
  
  constructor(invoiceData) {
    this.invoiceData = invoiceData;
  }

  getItems() {
    return this.invoiceData.items.map(item => {
      const itemSummary = InvoiceSummaryProvider.getItemSummary(item.quantity, item.price, item.vatPercentage, item.discountPercentage);
      return { ...item, ...itemSummary };
    });
  }

  getVatCategories() {
    const items = this.getItems();

    let vatCategories = [];

    const getVatCategory = (rate) => {
      let foundCategory = null;

      vatCategories.forEach(category => {
        if (category.rate === rate) {
          foundCategory = category;
        }
      });

      return foundCategory;
    };

    items.forEach(item => {
      let vatCategory = getVatCategory(item.vatPercentage);
      if (!vatCategory) {
        vatCategory = {
          taxableAmount: item.netoPriceSumWithDiscount,
          taxAmount: item.vatPrice,
          rate: item.vatPercentage
        }; // VatCategory.ts
        vatCategories.push(vatCategory);
      } else {
        vatCategory.taxableAmount += item.netoPriceSumWithDiscount;
        vatCategory.taxAmount += item.vatPrice;
      }
    });

    return vatCategories;
  }

  getSummary() {    
    const items = this.getItems();
    return this.getSummaryFromItems(items);
  }

  getSummaryFromItems(items) {
    return InvoiceSummaryProvider.getItemListSummary(items);
  }

  getYear() {
    const serviceDate = this.invoiceData.serviceDate.toDate();

    // If timeZoneOffset is set on the invoice, convert the service date to the time zone of the client.
    // This way getFullYear returns the year of the client's time zone.
    if (this.invoiceData.timeZoneOffset !== undefined) {
      const currentMachineTimeZoneOffset = serviceDate.getTimezoneOffset();
      if (currentMachineTimeZoneOffset !== this.invoiceData.timeZoneOffset) {
        serviceDate.setTime(serviceDate.getTime() - (this.invoiceData.timeZoneOffset * 60 * 1000));
      }
    }

    return serviceDate.getFullYear().toString();
  }

}

import { useState } from 'react';
import InvoiceAttachmentFile from 'types/InvoiceAttachmentFile';
import InvoiceStatus from 'types/InvoiceStatus';
import InvoiceType from 'types/InvoiceType';

const useInvoiceMetadata = () => {
  
  const [invoiceNumberLabelText, setInvoiceNumberLabelText] = useState<string | null>(null);
  const [invoiceNumberPrefixText, setInvoiceNumberPrefixText] = useState<string | null>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<number | null>(null);
  const [invoiceNumberYear, setInvoiceNumberYear] = useState<number | null>(null);
  const [invoiceNumberText, setInvoiceNumberText] = useState<string | null>(null);
  const [dateCreated, setDateCreated] = useState<Date | null>(null);
  const [missingDateCreated, setMissingDateCreated] = useState<string | null>(null);
  const [serviceDateIndex, setServiceDateIndex] = useState(0);
  const [serviceDateRange, setServiceDateRange] = useState<(Date | null)[]>([null, null]);
  const [startServiceDate, endServiceDate] = serviceDateRange;
  const [missingServiceDate, setMissingServiceDate] = useState<string | null>(null);
  const [serviceDateRangeVisible, setServiceDateRangeVisible] = useState(false);
  const [liquidationDateDays, setLiquidationDateDays] = useState<number | null>(null);
  const [liquidationDateDaysVisible, setLiquidationDateDaysVisible] = useState(false);
  const [liquidationDate, setLiquidationDate] = useState<Date | null>(null);
  const [missingLiquidationDate, setMissingLiquidationDate] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);
  const [status, setStatus] = useState<InvoiceStatus | null>(null);
  const [statusVisible, setStatusVisible] = useState(false);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [nextInvoiceNumberEditable, setNextInvoiceNumberEditable] = useState(false);
  const [invoiceNumberEditable, setInvoiceNumberEditable] = useState(false);
  const [attribution, setAttribution] = useState<string | null>(null);
  const [invoiceType, setInvoiceType] = useState<InvoiceType | null>(null);
  const [attachments, setAttachments] = useState<InvoiceAttachmentFile[]>([]);
  const [fromInvoiceNumberText, setFromInvoiceNumberText] = useState<string | null>(null);

  const setLiquidationDateWithDateAndDays = (dateCreated: Date, daysToAdd: number) => {
    const newDate = new Date(dateCreated);
    newDate.setDate(newDate.getDate() + daysToAdd);
    setLiquidationDate(newDate);
  };

  const setStartServiceDate = (value: Date) => {
    let newDateRange = serviceDateRange;
    newDateRange[0] = value;
    setServiceDateRange(newDateRange);
  };
  
  const setEndServiceDate = (value: Date) => {
    let newDateRange = serviceDateRange;
    newDateRange[1] = value;
    setServiceDateRange(newDateRange);
  };

  const validate = () => {
    setMissingDateCreated(dateCreated ? null : 'Obvezen podatek.');
    setMissingLiquidationDate(liquidationDate ? null : 'Obvezen podatek.');
    setMissingServiceDate((startServiceDate && endServiceDate) ? null : 'Obvezen podatek.');
    return dateCreated && liquidationDate && startServiceDate && endServiceDate;
  };
  
  const addAttachment = (attachmentId: string, fileName: string) => {
    const newAttachment : InvoiceAttachmentFile = {
      id: attachmentId,
      fileName: fileName,
      uploading: true
    };
    
    setAttachments(previousAttachments => {
      return [
        ...previousAttachments,
        newAttachment
      ];
    });
  };
  
  const setAttachmentUploadProgress = (attachmentId: string, progress: number) => {
    setAttachments(previousAttachments => {
      return previousAttachments.map(attachment => {
        if (attachment.id === attachmentId) {
          console.log('Upload is ' + progress + '% done');
          return {
            ...attachment,
            uploadProgress: progress
          }
        }
  
        return attachment;
      });
    });
  };

  const markAttachmentAsUploaded = (attachmentId: string, url: string | undefined) => {
    setAttachments(previousAttachments => {
      return previousAttachments.map(attachment => {
        if (attachment.id === attachmentId) {
          return {
            ...attachment,
            uploading: false,
            url: url
          }
        }
  
        return attachment;
      });
    });
  };

  const setAttachmentErrorMessage = (attachmentId: string, errorMessage: string) => {
    setAttachments(previousAttachments => {
      return previousAttachments.map(attachment => {
        if (attachment.id === attachmentId) {
          return {
            ...attachment,
            uploading: false,
            errorMessage: errorMessage
          }
        }
  
        return attachment;
      });
    });
  };

  const removeAttachment = (attachmentId: string) => {
    setAttachments(previousAttachments => {
      return previousAttachments.filter(attachment => attachment.id !== attachmentId)
    });
  };

  const getValidAttachments = () => {
    return attachments.filter(attachment => attachment.url);
  };

  const getInvoiceNumberEditingDisabledText = () => {
    if (!invoiceNumberEditable) {
      return "Številko računa lahko spremenite le pri ustvarjanju novega računa.";
    }
    
    if (!nextInvoiceNumberEditable) {
      return "Številko računa lahko spremenite le pri ustvarjanju računa za tekoče leto.";
    }

    return null;
  };

  return {
    invoiceNumberLabelText,
    setInvoiceNumberLabelText,
    invoiceNumber,
    setInvoiceNumber,
    invoiceNumberYear,
    setInvoiceNumberYear,
    invoiceNumberPrefixText,
    setInvoiceNumberPrefixText,
    setLiquidationDateWithDateAndDays,
    invoiceNumberText,
    setInvoiceNumberText,
    dateCreated,
    setDateCreated,
    missingDateCreated,
    serviceDateIndex,
    setServiceDateIndex,
    startServiceDate,
    setStartServiceDate,
    endServiceDate,
    setEndServiceDate,
    setServiceDateRange,
    serviceDateRangeVisible,
    setServiceDateRangeVisible,
    missingServiceDate,
    liquidationDateDays,
    setLiquidationDateDays,
    liquidationDateDaysVisible,
    setLiquidationDateDaysVisible,
    liquidationDate,
    setLiquidationDate,
    missingLiquidationDate,
    currency,
    setCurrency,
    status,
    setStatus,
    statusVisible,
    setStatusVisible,
    validate,
    organizationId,
    setOrganizationId,
    nextInvoiceNumberEditable,
    setNextInvoiceNumberEditable,
    invoiceNumberEditable,
    setInvoiceNumberEditable,
    attribution,
    setAttribution,
    invoiceType,
    setInvoiceType,
    attachments,
    setAttachments,
    addAttachment,
    setAttachmentUploadProgress,
    markAttachmentAsUploaded,
    setAttachmentErrorMessage,
    removeAttachment,
    getValidAttachments,
    fromInvoiceNumberText,
    setFromInvoiceNumberText,
    getInvoiceNumberEditingDisabledText
  };

};

export default useInvoiceMetadata;

import React, {useState} from 'react';
import TextBox from './TextBox';
import { ReactComponent as PictureIcon } from 'icons/picture.svg';
import Localizer from 'utils/Localizer';
import SignatureFileUploader from 'utils/SignatureFileUploader';
import { useFirebase } from 'config/Firebase';

type SignatureBoxProps = {
  
  label: string;
  
  className: string;
  
  defaultTextValue: string;

  // File url is not on the organizations created before 28.8.2021
  defaultFileUrlValue: string | null;
  
  textBoxName: string;

  fileUrlName: string;

  register: any;

  organizationId: string;

}

const SignatureBox = (props: SignatureBoxProps) => {
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(props.defaultFileUrlValue);
	const firebaseContext = useFirebase();
	const filePreviewVisible = selectedFile || selectedFileUrl;

	const changeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedFile = event.target.files && event.target.files.length > 0 ? event.target.files[0] : null;

    // If some file was already selected but now the user clicked cancel button on the file dialog, don't deselect the file
    // and keep the currently selected file.
    if (selectedFile && !newSelectedFile) {
      return;
    }
    
    // This will clear the input value selection. This fixes the change event not being called if image was removed and then
    // selected one more time.
    event.target.value = '';
    
    setSelectedFile(newSelectedFile);
    
    if (newSelectedFile) {
      const fileUploader = new SignatureFileUploader(firebaseContext!.firebase);
      if (!fileUploader.isFileTooLarge(newSelectedFile)) {
        const url = await fileUploader.uploadFile(newSelectedFile, props.organizationId);
        setSelectedFileUrl(url);
      }
    } else {
      setSelectedFileUrl(null);
    }
	};
  
  const removeButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    
    setSelectedFile(null);
    setSelectedFileUrl(null);
  };

  const fileInput = (
    <input type="file" accept="image/*" onChange={changeHandler} />
  );

  const getInput = () => {
    return (
      <label className="signature-file-upload">
        <div className="button" title="Naloži sliko ...">
          <PictureIcon />
        </div>
        {fileInput}
      </label>
    );
  };

  const getFilePreview = () => {
    if (!filePreviewVisible) {
      return null;
    }

    const sizeError = selectedFile && selectedFile.size > SignatureFileUploader.maxFileSize ? (
      <div className="error signature-file-size-error">Velikost slike je {Localizer.getNumberString(selectedFile.size / 1024 / 1024, 0)} MB. Največja dovoljena velikost je 0,5 MB!</div>
    ) : null;

    return (
      <>
        <label className="text-box control-row full-line">
          <span className="text">{props.label}</span>
          <div className="input-holder">
            <div className="flex-wrapper">
              <div className="floaty signature-file-deselect" onClick={removeButtonClick}>
                  &#10005;
              </div>
              <div className="inputish signature-file-preview">
                <img src={selectedFile ? URL.createObjectURL(selectedFile) : selectedFileUrl!} alt="Slika podpisa" />
              </div>
            </div>
            {sizeError}
          </div>
          {fileInput}
        </label>
      </>
    );
  };

	return (
    <>
      <TextBox name={props.textBoxName} className={props.className} fullLine label={props.label} multiLine defaultValue={props.defaultTextValue} placeholder="Vnesi podpis ali naloži sliko podpisa" info={getInput()} visible={!filePreviewVisible} ref={props.register} />
      {getFilePreview()}
      <input name={props.fileUrlName} type="hidden" value={selectedFileUrl ? selectedFileUrl : ""} ref={props.register} />
    </>
	)
};

export default SignatureBox;

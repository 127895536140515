import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App.js';
import AppProviders from 'components/AppProviders.js';
import ErrorView from 'components/ErrorView.js';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import 'normalize.css';
import 'styles/layout.scss';
import Firebase, { FirebaseContextProvider } from 'config/Firebase';

const bugsnagSettings = {
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
  appVersion: process.env.REACT_APP_VERSION,
  enabledReleaseStages: ['staging', 'production']
};
Bugsnag.start(bugsnagSettings);

const firebaseContext = {
  firebase: new Firebase()
};

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const rootElement = document.getElementById('root');
const content = (
  <ErrorBoundary FallbackComponent={ErrorView}>
    <FirebaseContextProvider value={firebaseContext}>
      <AppProviders>
        <App />
      </AppProviders>
    </FirebaseContextProvider>
  </ErrorBoundary>
);
ReactDOM.render(content, rootElement);

import React from 'react';
import AsyncSelect from 'react-select/async';
import OrganizationOption from 'components/forms/OrganizationOption.js';
import useGlobalOrganizationSearch from 'hooks/useGlobalOrganizationSearch.js';
import useInternalPartnerSearch from 'hooks/useInternalPartnerSearch.js';
import getSelectStyle from 'styles/SelectStyle.js';

const OrganizationSelectBox = ({ name, value, text, register, errors, onChange, searchGlobalOrganizations, searchPartners, autoFocus, placeholder, addText }) => {

  const selectedOption = (value || text) ? {
    value: value ? value : '',
    label: text ? text : ''
  } : null;

  const globalOrganizationSearch = useGlobalOrganizationSearch(addText);
  const internalPartnerSearch = useInternalPartnerSearch();
    
  const handleChange = (newSelectedOption) => {    
    if (onChange) {
      onChange(newSelectedOption);
    }
  };
  
  const loadOptions = async (inputValue) => {
    const globalPromise = searchGlobalOrganizations ? globalOrganizationSearch.get(inputValue) : null;
    const internalPromise = searchPartners ? internalPartnerSearch.get(inputValue) : null;
    const [globalOrganizations, internalPartners] = await Promise.allSettled([globalPromise, internalPromise]);
    if (globalOrganizations.value && internalPartners.value) {
      return internalPartners.value.concat(globalOrganizations.value);
    }

    if (globalOrganizations.value) {
      return globalOrganizations.value;
    }

    if (internalPartners.value) {
      return internalPartners.value;
    }

    return [];
  };

  const idInputName = name + 'Id';
  const nameInputName = name + 'Name';

  const validationError = errors ? errors[idInputName] : null;
  const errorMessage = validationError ? (
    <div className="error">
      {validationError.message}
    </div>
  ) : null;

  const selectStyle = getSelectStyle(validationError);
  const loadingMessage = "Iščem po Ajpesu ...";
  const placeholderText = placeholder ? placeholder : "Ime ali davčna št. organizacije ...";

  if (!searchGlobalOrganizations && !searchPartners) {
    throw new Error('Invalid partner query applied');
  }
  
  return (
    <>
      <AsyncSelect
      autoFocus={autoFocus}
      cacheOptions
      defaultOptions={false}
      isSearchable={true}
      loadOptions={loadOptions}
      onChange={handleChange}
      value={selectedOption}
      name={name}
      placeholder={placeholderText}
      isClearable
      loadingMessage={() => loadingMessage}
      styles={selectStyle}
      components={{ Option: OrganizationOption, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
      noOptionsMessage={obj => obj.inputValue.length > 0 ? 'Ni zadetkov.' : 'Za iskanje začnite tipkati ...' } />
      <input type="hidden" name={idInputName} value={selectedOption ? selectedOption.value : ''} ref={register} />
      <input type="hidden" name={nameInputName} value={selectedOption ? selectedOption.label : ''} ref={register} />
      {errorMessage}
    </>
  );
};

export default OrganizationSelectBox;

import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import { ReactComponent as DropdownArrow } from 'icons/dropdown-arrow.svg';
import { Link } from "react-router-dom";

type CreateButtonSecondaryAction = {
  to?: string | undefined;
  onClick?: MouseEventHandler<HTMLSpanElement> | undefined;
  text: string;
};

type SecondaryCreateButtonProps = {
  secondaryActions: CreateButtonSecondaryAction[];
};

const SecondaryCreateButton = (props: SecondaryCreateButtonProps) => {
  const showButtonRef = useRef<HTMLSpanElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuVisible, setMenuVisible] = useState(false);
  
  const getSecondaryLink = (secondaryAction: CreateButtonSecondaryAction) => {
    const key = secondaryAction.to + secondaryAction.text;
    if (secondaryAction.to) {
      return (
        <Link key={key} className="dropdown-item secondary-action" to={secondaryAction.to}>{secondaryAction.text}</Link>
      )
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, any>) => {
      setMenuVisible(false);
      
      if (secondaryAction.onClick) {
        secondaryAction.onClick(e);
      }
    }

    if (secondaryAction.onClick) {
      return (
        <button key={key} className="dropdown-item secondary-action link" onClick={handleClick}>{secondaryAction.text}</button>
      );;
    }

    throw new Error('Invalid secondary action');
  };

  const secondaryLinks = props.secondaryActions.map(secondaryAction => {
    return getSecondaryLink(secondaryAction);
  });

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  
  useEffect(() => {
    const hideMenu = (e: MouseEvent) => {
      const showButtonClicked = showButtonRef.current && e.target && (showButtonRef.current === e.target || showButtonRef.current.contains(e.target as any));
      const menuClicked = menuRef.current && e.target && (menuRef.current === e.target || menuRef.current.contains(e.target as any));
      if (menuVisible && !showButtonClicked && !menuClicked) {
        setMenuVisible(false);
      }
    }

    document.addEventListener("mousedown", hideMenu);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", hideMenu);
    }
  }, [menuVisible]);

  const menu = menuVisible ? (
    <div className="secondary-menu-holder">
      <div className="dropdown-list secondary-menu" ref={menuRef}>
        {secondaryLinks}
      </div>
    </div>
  ) : null;

  return (
    <>
      <span className="buttonish secondary-create" onClick={toggleMenu} ref={showButtonRef}>
        <DropdownArrow />
      </span>
      {menu}
    </>
  )
};

export type { CreateButtonSecondaryAction };

export default SecondaryCreateButton;

import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as ControlPanelRoutes from 'constants/ControlPanelRoutes';
import Router from 'utils/Router';
import ControlPanelListTitle from './ControlPanelListTitle';
import ListLink from './ListLink';

type OrganizationParams = {
  
  organizationId: string;

};

const Organization = ({ match }: RouteComponentProps<OrganizationParams>) => {

  return (
    <div className="page">
      <div className="dummy-top"></div>
      <h1>Organizacija</h1>
      <div className="panel">
        <div className="list">
          <ControlPanelListTitle>Možnosti</ControlPanelListTitle>
          <ListLink to={Router.route(ControlPanelRoutes.organizationUpgradeFunctions, { organizationId: match.params.organizationId })}>Upgrades</ListLink>
          <ListLink to={Router.route(ControlPanelRoutes.organizationSubscriptions, { organizationId: match.params.organizationId })}>Subscriptions</ListLink>
        </div>
      </div>
    </div>
  );

};

export default withRouter(Organization);

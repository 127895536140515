import React from 'react';

const FullPageSpinner = (props) => {
  return (
    <div className="full-page-spinner">
      <span className="info-text small">{props.text}</span>
    </div>
  );
};

export default FullPageSpinner;

import React from 'react';
import TextBox from 'components/forms/TextBox.js';
import DateBox from 'components/forms/DateBox';
import CurrencyBox from 'components/forms/CurrencyBox';
import InvoiceStatusSelectBox from 'components/documents/invoice/InvoiceStatusSelectBox.js';
import InvoiceLiquidationDate from 'components/documents/invoice/InvoiceLiquidationDate';
import InvoiceNumberProvider from 'utils/InvoiceNumberProvider';
import InvoiceAttributionProvider from 'utils/InvoiceAttributionProvider';
import useInvoiceNumberingModal from 'hooks/useInvoiceNumberingModal';
import useChangeAttributionTextModal from 'hooks/useChangeAttributionTextModal';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import InvoiceServiceDate from './InvoiceServiceDate';
import OrganizationData from 'types/OrganizationData';
import LastInvoicesPerYear from 'types/LastInvoicesPerYear';
import InvoiceNumberPrefixText from 'types/InvoiceNumberPrefixText';
import InvoiceNumberPrefixTextProvider from 'utils/InvoiceNumberPrefixTextProvider';
import InvoiceStatus from 'types/InvoiceStatus';

type InvoiceMetadataProps = {
  metadata: any;
  organization: OrganizationData;
  attribution: string;
  onOrganizationDataChanged: (newOrganizationData: OrganizationData) => void;
}

const InvoiceMetadata = (props: InvoiceMetadataProps) => {

  const changeInvoiceNumber = (lastInvoices: LastInvoicesPerYear[], invoiceNumberPrefixTexts: InvoiceNumberPrefixText[], startServiceDate: Date | null) => {
    const nonNullableStartServiceDate = startServiceDate ? startServiceDate : new Date();

    const invoiceNumberYear = nonNullableStartServiceDate.getFullYear();
    const newInvoiceNumber = InvoiceNumberProvider.getNextInvoiceNumber(props.metadata.invoiceType.id, invoiceNumberYear, lastInvoices);
    props.metadata.setInvoiceNumber(newInvoiceNumber);

    props.metadata.setInvoiceNumberYear(invoiceNumberYear);
    
    const invoiceNumberPrefixText = InvoiceNumberPrefixTextProvider.getPrefixText(invoiceNumberPrefixTexts, props.metadata.invoiceType.id);
    props.metadata.setInvoiceNumberPrefixText(invoiceNumberPrefixText);

    const invoiceNumberText = InvoiceNumberProvider.getInvoiceNumberText(newInvoiceNumber, invoiceNumberYear, invoiceNumberPrefixText);
    props.metadata.setInvoiceNumberText(invoiceNumberText);

    const attribution = InvoiceAttributionProvider.getAttributionText(props.attribution, newInvoiceNumber, invoiceNumberYear, props.organization.bankAccount);
    props.metadata.setAttribution(attribution);
    
    const isNextInvoiceNumberEditable = invoiceNumberYear === new Date().getFullYear();
    props.metadata.setNextInvoiceNumberEditable(isNextInvoiceNumberEditable);

    const newOrganizationData: OrganizationData = {
      ...props.organization
    };
    newOrganizationData.lastInvoices = lastInvoices;
    newOrganizationData.invoiceNumberPrefixTexts = invoiceNumberPrefixTexts;
    props.onOrganizationDataChanged(newOrganizationData);
  };

  const onInvoiceNumberChange = (lastInvoices: LastInvoicesPerYear[], invoiceNumberPrefixTexts: InvoiceNumberPrefixText[]) => {
    changeInvoiceNumber(lastInvoices, invoiceNumberPrefixTexts, props.metadata.startServiceDate);
  };

  const disabledText = props.metadata.getInvoiceNumberEditingDisabledText();
  const invoiceNumberingModal = useInvoiceNumberingModal(props.metadata.organizationId, disabledText, props.metadata.invoiceType.id, onInvoiceNumberChange);

  const setNewAttributionTextAfterStatusChange = () => {
    props.metadata.setAttribution(changeAttributionTextModal.potentialAttributionText);
  };

  const changeAttributionTextModal = useChangeAttributionTextModal(setNewAttributionTextAfterStatusChange);

  const handleServiceDateIndexChange = (dates: Date[] | undefined, index: number | undefined) => {
    const customDateRangeSelected = !props.metadata.serviceDateRangeVisible && !dates;
    if (!customDateRangeSelected) {
      props.metadata.setServiceDateIndex(index);
    }

    const dateCreated = props.metadata.dateCreated ? props.metadata.dateCreated : new Date();
    const serviceDateRange = customDateRangeSelected ? [dateCreated, dateCreated] : dates;
    props.metadata.setServiceDateRange(serviceDateRange);
    props.metadata.setServiceDateRangeVisible(customDateRangeSelected);

    if (props.metadata.invoiceNumberEditable) {
      changeInvoiceNumber(props.organization.lastInvoices, props.organization.invoiceNumberPrefixTexts, serviceDateRange !== undefined ? serviceDateRange[0] : null);
    }
  };

  const handleServiceDateRangeChange = (date: Date | (Date | null)[] | null) => {
    props.metadata.setServiceDateRange(date);

    if (props.metadata.invoiceNumberEditable) {
      changeInvoiceNumber(props.organization.lastInvoices, props.organization.invoiceNumberPrefixTexts, date !== undefined && date instanceof Array ? date[0] : null);
    }
  };

  const handleLiquidationDateDaysChange = (selectedOption: any) => {
    props.metadata.setLiquidationDateDays(selectedOption.value ? selectedOption.value : null);
    props.metadata.setLiquidationDateDaysVisible(selectedOption.value ? true : false);

    const daysToAdd = selectedOption.value ? selectedOption.value : props.metadata.liquidationDateDays;
    props.metadata.setLiquidationDateWithDateAndDays(props.metadata.dateCreated, daysToAdd);
  };

  const handleDateCreatedChange = (date: Date | (Date | null)[] | null) => {
    props.metadata.setDateCreated(date);

    if (props.metadata.liquidationDateDaysVisible) {
      props.metadata.setLiquidationDateWithDateAndDays(date, props.metadata.liquidationDateDays);
    }
  };

  const handleStatusChange = (newStatusString: string) => {
    const newStatus = newStatusString as InvoiceStatus;
    props.metadata.setStatus(newStatus);
    
    if (props.metadata.invoiceNumberEditable) {
      const potentialAttributionText = InvoiceAttributionProvider.getPotentialAttributionText(newStatus, props.metadata.fromInvoiceNumberText);
      if (potentialAttributionText) {
        changeAttributionTextModal.setPotentialAttributionText(potentialAttributionText);
        changeAttributionTextModal.open();
      }
    }
  };

  const editNumber = (    
    <span className="control-info edit-invoice-numbering-info" title="Urejaj številčenje računov" onClick={invoiceNumberingModal.open}>
      <EditIcon />
    </span>
  );

  return (
    <>
      <TextBox type="text" label={props.metadata.invoiceNumberLabelText} info={editNumber} disabled value={props.metadata.invoiceNumberText} />
      <DateBox label="Datum računa" value={props.metadata.dateCreated} validationFailed={props.metadata.missingDateCreated} onChange={handleDateCreatedChange} />
      <InvoiceServiceDate value={props.metadata.serviceDateIndex} validationFailed={props.metadata.missingServiceDate} onChange={handleServiceDateIndexChange} visible={!props.metadata.serviceDateRangeVisible} />
      <DateBox label="Datum storitve" selectsRange={true} startDate={props.metadata.startServiceDate} endDate={props.metadata.endServiceDate} validationFailed={props.metadata.missingServiceDate} onChange={handleServiceDateRangeChange} visible={props.metadata.serviceDateRangeVisible} />
      <InvoiceLiquidationDate visible={props.metadata.liquidationDateDaysVisible} dateCreated={props.metadata.dateCreated ? props.metadata.dateCreated : new Date()} value={props.metadata.liquidationDateDays} onChange={handleLiquidationDateDaysChange} />
      <DateBox label="Rok plačila" visible={!props.metadata.liquidationDateDaysVisible} value={props.metadata.liquidationDate} validationFailed={props.metadata.missingLiquidationDate} onChange={props.metadata.setLiquidationDate} />
      <CurrencyBox label="Valuta" value={props.metadata.currency} onChange={props.metadata.setCurrency} />
      <InvoiceStatusSelectBox label="Status" value={props.metadata.status} visible={props.metadata.statusVisible} onChange={handleStatusChange} fullLine={undefined} isClearable={undefined} style={undefined} />
      {invoiceNumberingModal.render()}
      {changeAttributionTextModal.render()}
    </>
  );
};

export default InvoiceMetadata;

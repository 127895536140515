import React from 'react';
import Select from 'react-select';
import getSelectStyle from 'styles/SelectStyle.js';

const SelectBox = ({ className, fullLine, label, visible, style, value, isClearable, options, onChange, components, defaultMenuIsOpen }) => {
  if (visible === undefined) {
    visible = true;
  }

  const getClassName = () => {
    let name = "select-box control-row";

    if (!visible) {
      name += " hidden";
    }

    if (fullLine) {
      name += " full-line";
    }

    if (className) {
      name += " " + className;
    }

    return name;
  };

  const selectStyle = getSelectStyle();
  const defaultStyle = {
    ...selectStyle,
    container: (provided) => ({
      ...provided,
      width: !fullLine ? '50%' : provided.width,
      display: !fullLine ? 'inline-block' : provided.display
    })
  };

  const handleChange = (newSelectedOption) => {
    if (onChange) {
      onChange(newSelectedOption);
    }
  };

  return (
    <label className={getClassName()}>
      {label ? (<span className="text">{label}</span>) : undefined}
      <Select
      className="select-box-control"
      defaultMenuIsOpen={defaultMenuIsOpen}
      options={options}
      placeholder="Izberi ..."
      styles={style ?? defaultStyle}
      value={value}
      onChange={handleChange}
      isClearable={isClearable}
      noOptionsMessage={() => 'Ni zadetkov'}
      components={components} />
    </label>
  );
};

export default SelectBox;

import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as DropdownIcon } from 'icons/dropdown.svg';

type MiniDropdownBoxProps = {
  className?: string | undefined;
  selectedOption: MiniDropdownBoxOption;
  options?: MiniDropdownBoxOption[] | undefined,
  onSelectedOptionChanged?: (oldSelectedOption: MiniDropdownBoxOption, newSelectedOption: MiniDropdownBoxOption) => void | undefined;
};

type MiniDropdownBoxOption = {
  value: string,
  text: string,
  className?: string | undefined
};

const MiniDropdownBox = (props: MiniDropdownBoxProps) => {
  
  const showButtonRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuVisible, setMenuVisible] = useState(false);

  const selectOption = (newSelectedOption: MiniDropdownBoxOption) => {
    setMenuVisible(false);

    const selectedOptionChanged = props.selectedOption !== newSelectedOption;
    if (!selectedOptionChanged) {
      return;
    }

    if (!props.onSelectedOptionChanged) {
      return;
    }

    props.onSelectedOptionChanged(props.selectedOption, newSelectedOption);
  };

  const options = props.options ? props.options.map(option => {
    return (
      <div key={option.value + option.text} className="dropdown-item" onClick={() => selectOption(option)}>
        {option.text}
      </div>
    );
  }) : [];

  const isReadonly = !props.options || props.options.length <= 1;

  const getClassName = () => {
    let className = "mini-dropdown-box noselect";

    if (props.className) {
      className += " " + props.className;
    }

    if (isReadonly) {
      className += " readonly";
    }

    if (menuVisible) {
      className += " menu-opened";
    }

    if (props.selectedOption.className) {
      className += " " + props.selectedOption.className;
    }

    return className;
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  
  useEffect(() => {
    const hideMenu = (e: MouseEvent) => {
      const showButtonClicked = showButtonRef.current && e.target && (showButtonRef.current === e.target || showButtonRef.current.contains(e.target as any));
      const menuClicked = menuRef.current && e.target && (menuRef.current === e.target || menuRef.current.contains(e.target as any));
      if (menuVisible && !showButtonClicked && !menuClicked) {
        setMenuVisible(false);
      }
    }

    document.addEventListener("mousedown", hideMenu);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", hideMenu);
    }
  }, [menuVisible]);

  const dropdownArrow = !isReadonly ? (
    <>
      <div className="dropdown-icon-placeholder" onClick={() => toggleMenu()} ref={showButtonRef}>
        <DropdownIcon />
      </div>
    </>
  ) : null;

  const menu = !isReadonly && menuVisible ? (
    <div className="mini-dropdown-menu dropdown-list" ref={menuRef}>
      {options}
    </div>
  ) : null;

  return (
    <div className="menu-dropdown-wrap">
      <div className={getClassName()}>
        <div className="text">
          {props.selectedOption.text}
        </div>
        {dropdownArrow}
      </div>
      {menu}
    </div>
  );

};

export default MiniDropdownBox;

export type { MiniDropdownBoxOption };

import React from 'react';
import OrganizationSelectBox from 'components/forms/OrganizationSelectBox.js';
import InvoiceStatusSelectBox from 'components/documents/invoice/InvoiceStatusSelectBox.js';
import InvoiceStatusType from 'types/InvoiceStatus';
import Organization from 'types/Organization';
import TextBox from './forms/TextBox';
import WarningEmoji from 'emojis/warning_26a0-fe0f.png';

type DocumentsFilterBarProps = {
  visible: boolean;
  isAnyFilterApplied: boolean;
  partner: Organization | null;
  onPartnerChanged: (partner: Organization | null) => void;
  status: InvoiceStatusType | null;
  onStatusChanged: (status: InvoiceStatusType | null) => void;
  invoiceNumber: string;
  onInvoiceNumberChanged: (invoiceNumber: string) => void;
  onFilterRemoved: () => void;
}

const DocumentsFilterBar = (props: DocumentsFilterBarProps) => {

  if (!props.visible) {
    return null;
  }

  const handlePartnerChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.organization) {
      props.onPartnerChanged(selectedOption.organization);
    } else {
      props.onPartnerChanged(null);
    }
  };

  const handleStatusChange = (status: InvoiceStatusType) => {
    props.onStatusChanged(status);
  };

  const handleInvoiceNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onInvoiceNumberChanged(event.target.value);
  };

  const activeFilterDescription = props.isAnyFilterApplied ? (
    <div className="active-description gray-uppercase">
      <img className="emoji top6" src={WarningEmoji} alt="warning" />
      Rezultati so filtrirani!
      <span className="link" onClick={() => props.onFilterRemoved()}>Odstrani vse filtre</span>
    </div>
  ) : null;

  return (
    <div className="documents-filter-bar">
      <h2 className="hide-on-mobile">Filter</h2>
      <div className="split-table">
        <div className="text-box control-row full-line">
          <span className="text">Partner</span>
          <OrganizationSelectBox 
          value={props.partner ? props.partner.id : null}
          text={props.partner ? props.partner.name : null}
          onChange={handlePartnerChange}
          placeholder="Naziv ali davčna št. partnerja ..."
          searchPartners
          name={undefined}
          register={undefined}
          errors={undefined}
          searchGlobalOrganizations={undefined}
          autoFocus={undefined}
          addText={undefined} />
        </div>
        <InvoiceStatusSelectBox
          value={props.status}
          fullLine
          isClearable
          label="Status"
          onChange={handleStatusChange}
          visible={undefined}
          style={undefined} />
        <TextBox 
          label="Številka računa"
          value={props.invoiceNumber}
          placeholder="X / XXXX"
          autoFocus={undefined}
          className={undefined}
          height={undefined}
          validationFailed={undefined}
          fullLine={true}
          multiLine={undefined}
          info={undefined}
          visible={undefined}
          onChange={handleInvoiceNumberChange} />
      </div>
      {activeFilterDescription}
    </div>
  )

};

export default DocumentsFilterBar;

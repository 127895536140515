import InvoiceData from '../types/InvoiceData';

class InvoiceProvider {

  get(rawData: any) {
    const invoiceData = rawData as InvoiceData;
    
    if (!rawData.invoiceNumberPrefixText) {
      invoiceData.invoiceNumberPrefixText = '';
    }

    // If invoiceNumberYear isn't set (this was the case before Februrary 2022), we calculate it from dateCreated year.
    // That way we simulate the same behaviour as it was before Februrary 2022.
    if (invoiceData.invoiceNumberYear === undefined && invoiceData.dateCreated) {
      const dateCreatedYear = invoiceData.dateCreated.toDate().getFullYear();
      invoiceData.invoiceNumberYear = dateCreatedYear;
    }
    
    if (rawData.taxReverseCharge === undefined) {
      invoiceData.taxReverseCharge = false;
    }

    return invoiceData;
  }

}

export default InvoiceProvider;

class Base64Provider {

  // Returns base 64 string from the provided blob.
  static getBase64String(blob: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        if (!reader.result) {
          throw new Error('Unable to get base 64 from blob');
        }

        if (reader.result instanceof ArrayBuffer) {
          return reader.result;
        }
        
        // Remove Data URI prefix and keep only Base64 string.
        const b64 = reader.result.replace(/^data:.+;base64,/, '');
        resolve(b64);
      };
    });
  }

}

export default Base64Provider;

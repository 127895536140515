class IbanHelper {

  static tryFormat(bankAccount: string) {
    const components = bankAccount.split('-');
    if (components.length !== 2 || components[0].length !== 5 || components[1].length !== 10) {
      return bankAccount;
    }

    const numbers = `${components[0]}${components[1]}`;
    const part1 = numbers.substring(0, 4);
    const part2 = numbers.substring(4, 8);
    const part3 = numbers.substring(8, 12);
    const part4 = numbers.substring(12, 15);
    return `SI56 ${part1} ${part2} ${part3} ${part4}`;
  }

}

export default IbanHelper;
import React from 'react';
import * as Routes from 'constants/Routes.js';

const ErrorView = ({ errorId }) => {
  
  const getDescriptionText = () => {
    switch (errorId) {
      case 'no-connection':
        return 'Ni internetne povezave.';
      case 'not-found':
        return 'Zahtevana stran ne obstaja.';
      default:
        return (
          <>
            Ups, nekaj se je zalomilo.<br />
            Ekipa Evelope je že bila obveščena o napaki.
          </>
        )
    }
  };

  const getButtonText = () => {
    switch (errorId) {
      case 'no-connection':
        return 'Poizkusi ponovno';
      default:
        return 'Na prvo stran';
    }
  };

  return (
    <div className="error-view">
      <img alt='Sad face smiley' src='/images/sad-face.png' />
      
      <div className="message">
        {getDescriptionText()}
      </div>

      <div className="link">
        <a className="buttonish" href={Routes.home}>{getButtonText()}</a>
      </div>
    </div>
  );
};

export default ErrorView;

import React from 'react'
import { AuthProvider } from 'utils/Auth'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    success: {
      main: '#11c16f'
    }
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif'
    ].join(','),
  }
});

const AppProviders = ({ children }) => {

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </AuthProvider>
  )
}

export default AppProviders

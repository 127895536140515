enum SubscriptionPriceType {
  Monthly,
  Yearly
}

type SubscriptionPrice = {
  id: string;
  text: string;
  type: SubscriptionPriceType;
}

export default SubscriptionPrice;

export { SubscriptionPriceType };

import React, { useState, useCallback } from 'react';
import { withRouter, Link, useLocation } from 'react-router-dom';
import TextBox from 'components/forms/TextBox.js';
import LegalFooter from 'components/LegalFooter.js';
import AuthenticationLogo from 'components/AuthenticationLogo.js';
import * as Routes from 'constants/Routes.js';
import Router from 'utils/Router.js';
import { useFirebase } from 'config/Firebase';
import Bugsnag from '@bugsnag/js';

const SignUp = () => {
  
  const [errorText, setErrorText] = useState('');
  const firebaseContext = useFirebase();

  const query = new URLSearchParams(useLocation().search);
  const defaultEmail = query.get('email');

  const [email, setEmail] = useState(defaultEmail ? defaultEmail : '');
  const [password, setPassword] = useState('');

  const signUpWithEmailAndPassword = useCallback(async event => {
    event.preventDefault();
    
    setErrorText('');    
    
    Bugsnag.leaveBreadcrumb('sign_up', { email, password });
    
    firebaseContext.firebase.createUserWithEmailAndPassword(email.trim(), password).then(() => {
      firebaseContext.firebase.logEvent('sign_up');
    }).catch((error) => {
      var errorCode = error.code;
      console.log('Sign up error: ' + errorCode);

      switch (errorCode) {
        case 'auth/weak-password':
          setErrorText('Geslo je prešibko.');
          break;
        case 'auth/email-already-in-use':
          setErrorText('Uporabnik s tem e-mail naslovom že obstaja.');
          break;
        case 'auth/invalid-email':
          setErrorText('Vnesite veljaven e-mail naslov.');
          break;
        case 'auth/network-request-failed':
          setErrorText('Preverite vašo internetno povezavo in poskusite ponovno.');
          break;
        default:
          console.log('Notify about error code: ' + errorCode);

          Bugsnag.notify(error, event => {
            event.addMetadata('errorContext', {
              errorCode: errorCode,
              email: email,
              password: password
            });
          });
          setErrorText('Pri registraciji je prišlo do napake: ' + errorCode);
          break;
      }
      
      console.log('Success with handling error code: ' + errorCode);
    });

  }, [firebaseContext, email, password]);
    
  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <form className="page authentication" onSubmit={signUpWithEmailAndPassword}>
      <AuthenticationLogo />
      <h1>Registracija novega uporabnika</h1>
      <div className="content">
        <TextBox fullLine name="email" type="email" label="E-mail" value={email} onChange={onEmailChange} />
        <TextBox fullLine name="password" type="password" label="Geslo" value={password} onChange={onPasswordChange} />
      </div>
      <div className="bottom-bar">
        <button className="buttonish text-only" type="submit">Registracija</button>
      </div>
      <div className="bottom-bar error">
        {errorText}
      </div>
      <div className="bottom-bar">
        Ste že registrirani? <Link to={Router.route(Routes.login, null, { email: email })}>Prijavite se</Link>
      </div>
      <LegalFooter />
    </form>
  );
};

export default withRouter(SignUp);

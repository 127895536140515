import InvoiceItem from "types/firestore_schema/InvoiceItem";
import InvoiceData from "types/InvoiceData";
import InvoiceSummary from "types/InvoiceSummary";
import VatCategory from "types/VatCategory";

class EInvoiceModel {

  invoiceData: InvoiceData;

  invoiceSummary: InvoiceSummary;
  
  itemsWithZeroVatPercentageAreTaxExemption: boolean;

  constructor(invoiceData: InvoiceData, invoiceSummary: InvoiceSummary) {
    this.invoiceData = invoiceData;
    this.invoiceSummary = invoiceSummary;
    this.itemsWithZeroVatPercentageAreTaxExemption = invoiceData.taxDescription.length > 0;
  }

  getInvoicedItemVatCategoryCode(invoiceItem: InvoiceItem) {
    return this.getVatCategoryCodeForTax(invoiceItem.vatPercentage);
  }

  getVatCategoryCode(vatCategory: VatCategory) {
    return this.getVatCategoryCodeForTax(vatCategory.rate);
  }

  getVatCategoryCodeForTax(tax: number) {
    if (this.invoiceData.taxReverseCharge) {
      return 'AE';
    }

    if (this.itemsWithZeroVatPercentageAreTaxExemption) {
      return 'E';
    }
    
    if (tax <= 0) {
      return 'Z';
    }

    return 'S';
  }

}

export default EInvoiceModel;

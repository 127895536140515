import React from 'react';
import InvoiceStatusLocalizer from 'utils/InvoiceStatusLocalizer.js';
import SelectBox from 'components/forms/SelectBox.js';

const InvoiceStatusSelectBox = ({ value, visible, label, style, onChange, fullLine, isClearable }) => {
  const getOption = (status) => {
    if (!status) {
      return null;
    }
    
    return {
      value: status,
      label: InvoiceStatusLocalizer.getText(status)
    };
  };

  const handleChange = (selectedValue) => {
    if (!onChange) {
      return;
    }

    if (selectedValue) {
      onChange(selectedValue.value);
    } else {
      onChange(null);
    }
  };

  const options = [
    getOption('Sent'),
    getOption('SentByEmail'),
    getOption('Paid'),
    getOption('PartiallyPaid'),
    getOption('Reversed')
  ];

  const selectedOption = getOption(value);

  return (
    <SelectBox
    label={label}
    visible={visible}
    value={selectedOption}
    isClearable={isClearable}
    options={options}
    fullLine={fullLine}
    onChange={handleChange}
    style={style} />
  );
};

export default InvoiceStatusSelectBox;

class FormHelper {
  
  static preventSubmitOnEnterKey(e: React.KeyboardEvent<HTMLFormElement>) {
    if (e.key === 'Enter') {
      // We still want enter to work fine for text areas.
      if ((e.target as any).nodeName === 'TEXTAREA') {
        return;
      }

      // We still want enter to work fine in case submit button is selected with tab.
      const typeAttribute = (e.target as any).getAttribute ? (e.target as any).getAttribute('type') : undefined;
      if (typeAttribute) {
        if (typeAttribute === 'submit') {
          return;
        }
      }

      e.preventDefault();
    }
  };

}

export default FormHelper;

class InvoiceServiceDateTextFormatter {

  static getServiceDateString(startServiceDate: Date, endServiceDate: Date, breakLine: boolean, cultureCode: string) {
    if (InvoiceServiceDateTextFormatter.isSameDay(startServiceDate, endServiceDate)) {
      return startServiceDate.toLocaleDateString(cultureCode);
    }

    const separator = !breakLine ? ' - ' : ' -\n';
    return `${startServiceDate.toLocaleDateString(cultureCode)}${separator}${endServiceDate.toLocaleDateString(cultureCode)}`;
  }
  
  private static isSameDay(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

}

export default InvoiceServiceDateTextFormatter;

import InvoiceData from '../types/InvoiceData';
import InvoiceProvider from 'providers/InvoiceProvider';
import OrganizationProvider from 'providers/OrganizationProvider';
import InvoiceType from 'types/InvoiceType';
import PartnerData from 'types/PartnerData';
import CollectionHelper from 'utils/CollectionHelper';
import InvoiceNumberPrefixTextProvider from 'utils/InvoiceNumberPrefixTextProvider';
import InvoiceNumberProvider from 'utils/InvoiceNumberProvider';
import InvoiceTypeProvider from 'utils/InvoiceTypeProvider';
import TaxDescriptionProvider from 'utils/TaxDescriptionProvider';

class InvoiceDataProvider {

  private db: FirebaseFirestore.Firestore;

  private invoiceType: InvoiceType;

  constructor(db: FirebaseFirestore.Firestore, invoiceTypeId: string | undefined) {
    this.db = db;
    this.invoiceType = InvoiceTypeProvider.getById(invoiceTypeId);;
  }

  get(requestInvoiceId: string | undefined, organizationId: string | undefined) {
    if (organizationId) {
      if (!requestInvoiceId) {
        return this.getNewInvoice(organizationId);
      }

      return this.getNewInvoiceFromExistingInvoice(requestInvoiceId, organizationId);
    }
    
    if (!requestInvoiceId) {
      throw new Error('requestInvoiceId and organizationId not set');
    }
    
    return this.getInvoice(requestInvoiceId);
  }

  // When user opens existing invoice
  private async getInvoice(invoiceId: string): Promise<InvoiceData> {
    const invoiceSnapshot = await this.getDocumentSnapshot('invoices', invoiceId);
    return new InvoiceProvider().get(invoiceSnapshot);
  }
  
  // When user creates new invoice from existing invoice
  private async getNewInvoiceFromExistingInvoice(invoiceId: string, organizationId: string): Promise<InvoiceData> {
    const invoicePromise = this.getInvoice(invoiceId);
    const organizationPromise = this.getOrganization(organizationId);
    const results = await Promise.all([invoicePromise, organizationPromise]);
    const invoiceData = results[0]; 
    const organizationData = results[1];

    const dateCreated = new Date();
    const serviceDate = invoiceData.serviceDate.toDate();
    
    const invoiceNumberYear = serviceDate.getFullYear();
    const invoiceNumber = InvoiceNumberProvider.getNextInvoiceNumber(this.invoiceType.id, invoiceNumberYear, organizationData.lastInvoices);
    const invoiceNumberPrefixText = InvoiceNumberPrefixTextProvider.getPrefixText(organizationData.invoiceNumberPrefixTexts, this.invoiceType.id);
    
    invoiceData.fromInvoiceId = invoiceId;
    invoiceData.fromInvoiceNumber = invoiceData.invoiceNumber;
    invoiceData.fromInvoiceNumberYear = invoiceData.invoiceNumberYear;
    invoiceData.fromInvoiceNumberPrefixText = invoiceData.invoiceNumberPrefixText;
    invoiceData.organization.lastInvoices = organizationData.lastInvoices;
    invoiceData.organization.invoiceNumberPrefixTexts = organizationData.invoiceNumberPrefixTexts;
    invoiceData.attribution = organizationData.invoiceAttribution;
    invoiceData.invoiceNumber = invoiceNumber;
    invoiceData.invoiceNumberYear = invoiceNumberYear;
    invoiceData.invoiceNumberPrefixText = invoiceNumberPrefixText;
    invoiceData.dateCreated = CollectionHelper.toTimestamp(dateCreated);
    invoiceData.status = 'Sent';
    invoiceData.invoiceTypeId = this.invoiceType.id;
    delete invoiceData.successorInvoices;

    return invoiceData;
  }

  private async getOrganization(organizationId: string) {  
    const organizationSnapshot = await this.getDocumentSnapshot('organizations', organizationId);
    return new OrganizationProvider().get(organizationSnapshot);
  }
  
  // When user creates new invoice
  private async getNewInvoice(organizationId: string): Promise<InvoiceData> {
    const organizationData = await this.getOrganization(organizationId);

    const dateCreated = new Date();
    const serviceDate = new Date();
    
    const invoiceNumberYear = serviceDate.getFullYear();
    const invoiceNumber = InvoiceNumberProvider.getNextInvoiceNumber(this.invoiceType.id, invoiceNumberYear, organizationData.lastInvoices);
    const invoiceNumberPrefixText = InvoiceNumberPrefixTextProvider.getPrefixText(organizationData.invoiceNumberPrefixTexts, this.invoiceType.id);

    if (!organizationData.bankAccount) {
      throw new Error('houston we have a problem');
    }

    const defaultTaxDescription = organizationData.taxDebtor === true ? '' : TaxDescriptionProvider.noTaxDebtorTaxDescription;
    const invoiceData: InvoiceData = {
      organization: {
        ...organizationData,
        id: organizationId
      },
      invoiceNumber: invoiceNumber,
      invoiceNumberYear: invoiceNumberYear,
      invoiceNumberPrefixText: invoiceNumberPrefixText,
      dateCreated: CollectionHelper.toTimestamp(dateCreated),
      serviceDate: CollectionHelper.toTimestamp(serviceDate),
      endServiceDate: CollectionHelper.toTimestamp(serviceDate),
      liquidationDate: CollectionHelper.toTimestamp(new Date()),
      currency: organizationData.currency,
      attribution: organizationData.invoiceAttribution,
      attachments: [],
      signature: organizationData.invoiceSignature,
      signatureFileUrl: organizationData.invoiceSignatureFileUrl ? organizationData.invoiceSignatureFileUrl : '',
      partner: {} as PartnerData, // hack for backwards compatibility
      status: 'Sent',
      items: [],
      taxDescription: defaultTaxDescription,
      taxReverseCharge: false,
      invoiceTypeId: this.invoiceType.id
    };

    return invoiceData;
  }
  
  private async getDocumentSnapshot(collectionName: string, documentId: string) {
    const document = await this.db.collection(collectionName).doc(documentId).get();
    if (!document.exists) {
      throw new Error(`${collectionName} document doesn't exist: ${documentId}`);
    }

    const snapshot = document.data();    
    if (!snapshot) {
      throw new Error(`${collectionName} snapshot not defined: ${documentId}`);
    }

    return snapshot;
  }

}

export default InvoiceDataProvider;

import React from 'react';
import DateBox from 'components/forms/DateBox';
import NumberBox from 'components/forms/NumberBox';
import TextBox from 'components/forms/TextBox';
import PaymentInfo from 'types/PaymentInfo';
import RemoveButton from 'components/RemoveButton';

type PaymentProps = {
  state: PaymentInfo;
  currency: string;
  index: number;
  autoFocus?: boolean | undefined;
  removeButtonVisible?: boolean | undefined;
  onChange: (newState: PaymentInfo) => void;
  onRemove: (removedPayment: PaymentInfo) => void;
}

const Payment = (props: PaymentProps) => {
  
  const handleAmountChange = (newAmount: number) => {
    const newState: PaymentInfo = {
      ...props.state,
      amount: newAmount
    };
    props.onChange(newState);
  };

  const handleDateChange = (date: Date | (Date | null)[] | null) => {
    const newState: PaymentInfo = {
      ...props.state,
      date: date as Date
    };
    props.onChange(newState);
  };
  
  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState: PaymentInfo = {
      ...props.state,
      note: event.target.value
    };
    props.onChange(newState);
  };

  const handleRemoveButtonClick = () => {
    props.onRemove(props.state);
  };

  const currencyInfo = (
    <div className="text-box-appendix">
      {props.currency}
    </div>
  );

  const removeButton = props.removeButtonVisible ? (
    <RemoveButton onClick={handleRemoveButtonClick} />
  ) : null;

  return (
    <div className={`split-table payment-history index${props.index}`}>
      {removeButton}
      <NumberBox className="small" fullLine label="Znesek" value={props.state.amount} info={currencyInfo} decimalScale={2} fixedDecimalScale autoFocus={props.autoFocus} onChange={handleAmountChange} />
      <DateBox className="small" fullLine label="Datum plačila" value={props.state.date} onChange={handleDateChange} />
      <TextBox className="big" fullLine label="Opomba" value={props.state.note} onChange={handleNoteChange} />
    </div>
  );
}

export default Payment;

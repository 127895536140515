import React, { useState } from 'react';
import SelectBox from 'components/forms/SelectBox.js';

const TaxDescription = ({ values, value, onChange }) => {

  const [visible, setVisible] = useState(value.length > 0);
  const [openedManually, setOpenedManually] = useState(false);
  
  const getOption = (text) => {
    return {
      value: text,
      label: text
    };
  };
  
  const options = [];

  values.forEach((taxDescription) => {
    const option = getOption(taxDescription);
    options.push(option);
  });

  const handleChange = (selectedValue) => {
    if (!onChange) {
      return;
    }

    if (selectedValue) {
      onChange(selectedValue.value);
    } else {
      onChange('');
      setVisible(false);
      setOpenedManually(false);
    }
  };

  const selectedOption = getOption(value);

  const addTaxDescription = () => {
    setVisible(true);
    setOpenedManually(true);
  };

  const label = 'Davek';
  if (!visible) {
    return (
      <>          
        <div className="line-label">{label}</div>
        <div className="invoice-attachment-list">
          <label className="upload-attachment link action" onClick={addTaxDescription}>Dodaj izjemo …</label>
        </div>
      </>
    );
  }

  return (
    <SelectBox
    fullLine
    className="tax-description"
    isClearable={true}
    label={label}
    visible={true}
    value={selectedOption}
    options={options}
    onChange={handleChange}
    defaultMenuIsOpen={openedManually} />
  );
};

export default TaxDescription;

import React from 'react';
import ListItem from 'components/ListItem';
import { ReactComponent as ArrowsIcon } from 'icons/arrows.svg';
import Pagination from 'components/Pagination.js';
import ListDataSource from 'types/ListDataSource';
import ListColumn from 'types/ListColumn';
import ListPager from 'types/ListPager';
import SortOption from 'types/SortOption';
import SortDirection from 'types/SortDirection';

type ListProps = {
  dataSource: ListDataSource;
  pager?: ListPager | undefined;
  emptyView?: any | undefined;
  sortOption?: SortOption | undefined;
  onSortChange?: (sortOption: SortOption) => void | undefined;
}

const List = (props: ListProps) => {
  if (!props.dataSource) {
    return <></>;
  }

  const isColumnSortable = (column: ListColumn) => {
    return props.onSortChange && column.sortFieldName && props.dataSource.rows.length > 1;
  };

  const getClassName = (column: ListColumn) => {
    if (!column.className) {
      return 'col';
    }
    
    return 'col ' + column.className;
  }
  
  const getSortHandleClassName = (column: ListColumn) => {
    let sortHandleClassName = 'text-list-item noselect';

    if (isColumnSortable(column)) {
      sortHandleClassName += ' clickable';
      
      if (props.sortOption?.fieldName && props.sortOption?.fieldName === column.sortFieldName) {
        sortHandleClassName += ' selected';
        sortHandleClassName += ' direction' + props.sortOption?.direction;
      }
    }
    
    return sortHandleClassName;
  }

  const handleSortChange = (column: ListColumn) => {
    if (!isColumnSortable(column)) {
      return;
    }

    const selectedOption : SortOption = {
      direction: SortDirection.Ascending,
      fieldName: column.sortFieldName!
    };

    // If the already selected sort option was clicked again, then just inverse the sort direction, but the field name will remain the same.
    if (props.sortOption?.fieldName === selectedOption.fieldName) {
      selectedOption.direction = props.sortOption!.direction === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
    }

    props.onSortChange!(selectedOption);
  }

  const headers = props.dataSource.columns.map((column) =>
    <li className={getClassName(column)} key={column.id}>
      <span className={getSortHandleClassName(column)} onClick={() => handleSortChange(column)}>
        {column.header}
        { isColumnSortable(column) ? <ArrowsIcon /> : null }
      </span>
    </li>
  );

  const rows = props.dataSource.rows.map((row) =>
    <ListItem to={row.href} onClick={row.onClick} key={row.id} className="row data">
      { props.dataSource.columns.map((column) =>
        <div className={getClassName(column)} key={column.id}>
          <span className="header inline show-only-on-mobile">{column.header}: </span>
          {row[column.id]}
        </div>
      )}
    </ListItem>
  );

  const pagination = props.pager ? (
    <Pagination pager={props.pager} />
  ) : null;

  const content = (
    <>
      <ol className="row header hide-on-mobile">
        {headers}
      </ol>
      {rows}
      {pagination}      
    </>
  );

  if (props.pager && props.pager.loading && rows.length === 0) {
    return null;
  }

  const body = rows.length > 0 ? content : props.emptyView;

  return (
    <div className="list">
      {body}
    </div>
  );
};

export default List;

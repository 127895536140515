import React, { useState } from 'react';
import InvoiceNumberingModal from 'components/modals/InvoiceNumberingModal';
import AlertModal from 'components/modals/AlertModal';
import LastInvoicesPerYear from 'types/LastInvoicesPerYear';
import InvoiceNumberPrefixText from 'types/InvoiceNumberPrefixText';

const useInvoiceNumberingModal = (organizationId: string, disabledText: string | null, visibleInvoiceTypeIdFilter: string | undefined, onSave: (nextInvoiceNumber: LastInvoicesPerYear[], invoiceNumberPrefixTexts: InvoiceNumberPrefixText[]) => void) => {
  
  const [opened, setOpened] = useState(false);

  const handleSave = (lastInvoices: LastInvoicesPerYear[], invoiceNumberPrefixTexts: InvoiceNumberPrefixText[]) => {
    onSave(lastInvoices, invoiceNumberPrefixTexts);
    
    close();
  };

  const close = () => {
    setOpened(false);
  };

  const open = () => {
    setOpened(true);
  };

  const render = () => {
    if (disabledText) {
      return (
        <AlertModal open={opened} onCancel={close} title="Številčenje računov" text={disabledText} cancelButtonText="V redu" />
      );
    }

    return (
      <InvoiceNumberingModal organizationId={organizationId} visibleInvoiceTypeIdFilter={visibleInvoiceTypeIdFilter} open={opened} onClose={close} onSave={handleSave} />
    );
  };

  return {
    close,
    open,
    render,
    opened
  }

};

export default useInvoiceNumberingModal;

import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'icons/add.svg';
import SecondaryCreateButton, { CreateButtonSecondaryAction } from './SecondaryCreateButton';

type CreateButtonProps = {
  to?: string | undefined;
  onClick?: MouseEventHandler<HTMLSpanElement> | undefined;
  children: any;
  secondaryActions?: CreateButtonSecondaryAction[] | undefined;
};

const CreateButton = (props: CreateButtonProps) => {
  const className = props.secondaryActions ? "buttonish create w-secondary" : "buttonish create";
  const secondaryButton = props.secondaryActions ? <SecondaryCreateButton secondaryActions={props.secondaryActions} /> : null;

  if (props.onClick) {
    return (
      <>
        <button className={className} onClick={props.onClick}>
          <AddIcon />
          {props.children}
        </button>
        {secondaryButton}
      </>
    );
  }

  if (!props.to) {
    throw new Error('Invalid CreateButton usage');
  }

  return (
    <>
      <Link className={className} to={props.to}>
        <AddIcon />
        {props.children}
      </Link>
      {secondaryButton}
    </>
  );
};

export default CreateButton;

import Bugsnag from "@bugsnag/js";
import { useEffect, useState } from "react";
import { useAuth } from "utils/Auth";

const useNumberOfInvoicesCreated = () => {
  
  const [numberOfInvoicesCreatedThisMonth, setNumberOfInvoicesCreatedThisMonth] = useState<number>();
  const { userData, createBackendRequest } = useAuth();
  
  useEffect(() => {
    const getNumberOfInvoicesCreatedThisMonth = async () => {
      let numberOfInvoices = 0;
      try {
        const axiosInstance = createBackendRequest();
        const result = await axiosInstance.get('/invoice-counter', {
          params: {
            organizationId: userData.organizationId
          }
        });
  
        numberOfInvoices = result.data.numberOfInvoices;
      }
      catch (error) {
        Bugsnag.notify(error);
        console.log(`Error while getting number of invoices: ${error}`);
      }

      setNumberOfInvoicesCreatedThisMonth(numberOfInvoices);
    };

    if (userData && numberOfInvoicesCreatedThisMonth === undefined) {
      getNumberOfInvoicesCreatedThisMonth();
    }
  }, [userData, numberOfInvoicesCreatedThisMonth, createBackendRequest]);
  
  return {
    numberOfInvoicesCreatedThisMonth
  };

};

export default useNumberOfInvoicesCreated;

import Bugsnag from '@bugsnag/js';
import firebase from 'firebase/app';
import PaymentInfo from 'types/PaymentInfo';
import InvoicePaymentHistoryData from 'types/InvoicePaymentHistoryData';
import InvoicePaymentData from 'types/InvoicePaymentData';
import CollectionHelper from './CollectionHelper';

class PaymentHistoryProvider {

  private db: firebase.firestore.Firestore;

  private userId: string;
  
  private historyRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  
  constructor(db: firebase.firestore.Firestore, userId: string, invoiceId: string) {
    this.db = db;
    this.userId = userId;
    this.historyRef = this.db.collection('invoices').doc(invoiceId).collection('history').doc('payments');
  }

  async get() {
    try {
      const snapshot = await this.historyRef.get();
      if (!snapshot.exists) {
        return [];
      }
      
      const invoicePaymentHistoryData = snapshot.data() as InvoicePaymentHistoryData;
      return invoicePaymentHistoryData.payments.map(paymentData => PaymentHistoryProvider.fromPaymentData(paymentData))
    }
    catch (error) {
      Bugsnag.notify(error);
      console.log('Error while getting payment history: ' + error);
      throw error;
    }
  }
      
  async save(payments: PaymentInfo[]) {    
    try {
      const paymentDatas = payments.map(payment => PaymentHistoryProvider.toPaymentData(payment));
      const invoicePaymentHistoryData: InvoicePaymentHistoryData = {
        authorUid: this.userId,
        payments: paymentDatas
      };
      await this.historyRef.set(invoicePaymentHistoryData);
    }
    catch (error) {
      Bugsnag.notify(error);
      console.log('Error while saving payment history: ' + error);
      throw error;
    }
  }
  
  private static toPaymentData(payment: PaymentInfo) {
    const paymentData: InvoicePaymentData = {
      id: payment.id,
      amount: payment.amount,
      date: CollectionHelper.toTimestamp(payment.date),
      note: payment.note
    };
    return paymentData;
  }
  
  private static fromPaymentData(payment: InvoicePaymentData) {
    const paymentInfo: PaymentInfo = {
      id: payment.id,
      amount: payment.amount,
      date: payment.date.toDate(),
      note: payment.note
    };
    return paymentInfo;
  }

}

export default PaymentHistoryProvider;

import InvoiceModel from '../models/InvoiceModel.js';
import EInvoiceModel from '../models/EInvoiceModel';
import Base64Provider from './Base64Provider';
import InvoiceTypeProvider from './InvoiceTypeProvider';
import PaymentReferenceProvider from './PaymentReferenceProvider';
const builder = require('xmlbuilder');

const downloadXml = (invoice) => {
  const element = document.createElement("a");
  const file = getXmlBlob(invoice);
  element.href = URL.createObjectURL(file);
  element.download = invoice.getFileName('xml');
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

const getTextAsString = (text, maximumNumberOfCharacters) => {
  if (!text) {
    return text;
  }

  return text.substring(0, maximumNumberOfCharacters);
};

const getNumberAsString = (number) => {
  if (!number) {
    return Number(0).toFixed(2);
  }

  return Number(number).toFixed(2);
};

const getTaxNumberAsString = (taxNumber) => {
  if (!taxNumber) {
    return taxNumber;
  }

  if (!taxNumber.startsWith('SI')) {
    return 'SI' + taxNumber;
  }

  return taxNumber;
};

const getXmlBase64 = (invoice) => {
  const blob = getXmlBlob(invoice);
  return Base64Provider.getBase64String(blob);
};

const dateString = (dateWithTimeZone) => {
  const utcDate = new Date(Date.UTC(dateWithTimeZone.getFullYear(), dateWithTimeZone.getMonth(), dateWithTimeZone.getDate(), 0, 0, 0));
  return utcDate.toISOString().slice(0, 10);
};

const getXmlBlob = (invoice) => {
  const xmlContent = generateXml(invoice);
  return new Blob([xmlContent], { type: 'text/xml' });
};

const generateXml = (invoice) => {
  if (!invoice || !invoice.data) {
    throw new Error('Unable to generate xml, invoice data is null: ' + invoice);
  }

  const invoiceModel = new InvoiceModel(invoice.data);
  const summary = invoiceModel.getSummary();
  const vatCategories = invoiceModel.getVatCategories();
  const items = invoiceModel.getItems();
  const eInvoiceModel = new EInvoiceModel(invoice.data, summary);

  const root = builder.create('Invoice');
  const mInvoiceElement = root
    .att('xmlns', 'urn:eslog:2.00')
    .att('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance')
    .ele('M_INVOIC')
    .att('Id', 'data');

  // S_UNH
  mInvoiceElement.ele('S_UNH')
    .ele('D_0062')
      .txt(invoice.metadata.invoiceNumberText)
    .up()
    .ele('C_S009')
      .ele('D_0065')
        .txt('INVOIC')
      .up()
      .ele('D_0052')
        .txt('D')
      .up()
      .ele('D_0054')
        .txt('01B')
      .up()
      .ele('D_0051')
        .txt('UN')
      .up()
    .up()
  .up();

  // Invoice number
  mInvoiceElement.ele('S_BGM')
    .ele('C_C002')
      .ele('D_1001')
        .txt(InvoiceTypeProvider.getESlogInvoiceTypeCode(invoice.metadata.invoiceType.id)) // BT-3
      .up()
    .up()
    .ele('C_C106')
      .ele('D_1004')
        .txt(invoice.metadata.invoiceNumberText) // BT-1
      .up()
    .up()
  .up();

  // Invoice date created
  mInvoiceElement.ele('S_DTM')
    .ele('C_C507')
      .ele('D_2005')
        .txt('137')
      .up()
      .ele('D_2380')
        .txt(dateString(invoice.metadata.dateCreated)) // BT-2
      .up()
    .up()
  .up()

  // Invoice service date (period start date)
  mInvoiceElement.ele('S_DTM')
    .ele('C_C507')
      .ele('D_2005')
        .txt('167')
      .up()
      .ele('D_2380')
        .txt(dateString(invoice.metadata.startServiceDate)) // BT-73
      .up()
    .up()
  .up()

  // Invoice service date (period end date)
  mInvoiceElement.ele('S_DTM')
    .ele('C_C507')
      .ele('D_2005')
        .txt('168')
      .up()
      .ele('D_2380')
        .txt(dateString(invoice.metadata.endServiceDate)) // BT-74
      .up()
    .up()
  .up()
  
  // Invoice note
  mInvoiceElement.ele('S_FTX')
      .ele('D_4451')
        .txt('GEN')
      .up()
      .ele('C_C108')
        .ele('D_4440')
          .txt(invoice.data.attribution) // BT-22
        .up()
      .up()
    .up()
  .up();

  // Specification identifier
  mInvoiceElement.ele('S_FTX')
      .ele('D_4451')
        .txt('DOC')
      .up()
      .ele('C_C108')
        .ele('D_4440')
          .txt('urn:cen.eu:en16931:2017') // BT-24
        .up()
      .up()
    .up()
  .up();
  
  // Tax exemption
  if (eInvoiceModel.itemsWithZeroVatPercentageAreTaxExemption) {
    mInvoiceElement.ele('S_FTX')
        .ele('D_4451')
          .txt('AGM')
        .up()
        .ele('C_C108')
          .ele('D_4440')
            .txt(invoice.itemList.taxDescription) // BT-120
          .up()
        .up()
      .up()  
    .up();
  }

  // Seller additional legal information
  mInvoiceElement.ele('S_FTX')
      .ele('D_4451')
        .txt('REG')
      .up()
      .ele('C_C108')
        .ele('D_4440')
          .txt('Export') // BT-33
        .up()
      .up()
    .up()
  .up();

  // Remittance information (BT-83)
  mInvoiceElement.ele('G_SG1')
    .ele('S_RFF')
      .ele('C_C506')
        .ele('D_1153')
          .txt('PQ')
        .up()
        .ele('D_1154')
          .txt(PaymentReferenceProvider.getPaymentReference(invoice.metadata.invoiceNumber, invoice.metadata.invoiceNumberYear))
        .up()
      .up()
    .up()
  .up();

  // Buyer data
  const buyerElement = mInvoiceElement.ele('G_SG2');

  const buyerNadElement = buyerElement.ele('S_NAD');
  buyerNadElement
    .ele('D_3035')
      .txt('BY')
    .up();

  if (invoice.partnerData.glnCode) {
    buyerNadElement
      .ele('C_C082')
        .ele('D_3039')
          .txt(invoice.partnerData.glnCode) // BT-46
        .up()
        .ele('D_1131')
          .txt('0088') // BT-46-1
        .up()
      .up()
  }

  buyerNadElement
    .ele('C_C080')
      .ele('D_3036')
        .txt(getTextAsString(invoice.partnerData.name, 70)) // BT-44
      .up()
    .up()
    .ele('C_C059')
      .ele('D_3042')
        .txt(invoice.partnerData.address) // BT-50
      .up()
    .up()
    .ele('D_3164')
      .txt(invoice.partnerData.city) // BT-52
    .up()
    .ele('D_3251')
      .txt(invoice.partnerData.postOffice) // BT-53
    .up()
    .ele('D_3207')
      .txt('SI') // BT-55
    .up()
  .up();

  // Buyer bank transfer details
  if (invoice.partnerData.bankAccount && invoice.partnerData.bankAccount.length > 0) {
    const buyerBankElement = buyerElement.ele('S_FII');
    buyerBankElement
      .ele('D_3035')
        .txt('BB')
      .up()
      .ele('C_C078')
        .ele('D_3194')
          .txt(invoice.partnerData.bankAccount)
        .up()
        .ele('D_3192')
          .txt(invoice.partnerData.name.substr(0, 35))
        .up()
      .up()
    .up()
  
    // Bank BIC code
    if (invoice.partnerData.bankBusinessIdentifierCode && invoice.partnerData.bankBusinessIdentifierCode.length > 0) {
      buyerBankElement.ele('C_C088')
        .ele('D_3433')
          .txt(invoice.partnerData.bankBusinessIdentifierCode)
        .up()
      .up();
    }
  }
  
  // Buyer country issued id
  // IF YOU CHANGE THIS SECTION, ALSO CHANGE THE SECTION BELOW INSIDE DELIVERY INFORMATION WHERE WE ALSO FOLLOW THE SAME SCHEMA.
  if (invoice.partnerData.countryIssuedId) {
    buyerElement.ele('G_SG3')
      .ele('S_RFF')
        .ele('C_C506')
          .ele('D_1153')
            .txt('0199') // BT-47-1
          .up()
          .ele('D_1154')
            .txt(invoice.partnerData.countryIssuedId) // BT-47
          .up()
        .up()
      .up()
    .up();
  }

  buyerElement.ele('G_SG3')
    .ele('S_RFF')
      .ele('C_C506')
        .ele('D_1153')
          .txt('VA')
        .up()
        .ele('D_1154')
          .txt(invoice.partnerData.taxNumber) // BT-48
        .up()
      .up()
    .up()
  .up();

  buyerElement.ele('G_SG3')
    .ele('S_RFF')
      .ele('C_C506')
        .ele('D_1153')
          .txt('AHP')
        .up()
        .ele('D_1154')
          .txt(invoice.partnerData.taxNumber) // NBT-013
        .up()
      .up()
    .up()
  .up();

  // Buyer country
  // if (invoice.partnerData.country.length > 0) {
  //   buyerNadElement.ele('C_C819')
  //     .ele('D_3228')
  //       .txt(invoice.partnerData.country) // BT-54
  //     .up()
  //   .up()
  // }


  // Delivery information (currently the schema is same as BY section but data could be different in the future)
  const deliveryInformationElement = mInvoiceElement.ele('G_SG2');

  const deliveryInformationNadElement = deliveryInformationElement.ele('S_NAD');
  deliveryInformationNadElement
    .ele('D_3035')
      .txt('DP')
    .up();

  if (invoice.partnerData.glnCode) {
    deliveryInformationNadElement
      .ele('C_C082')
        .ele('D_3039')
          .txt(invoice.partnerData.glnCode) // BT-71
        .up()
        .ele('D_1131')
          .txt('0088') // BT-71-1
        .up()
      .up()
  }

  deliveryInformationNadElement
    .ele('C_C080')
      .ele('D_3036')
        .txt(getTextAsString(invoice.partnerData.name, 70)) // BT-70
      .up()
    .up()
    .ele('C_C059')
      .ele('D_3042')
        .txt(invoice.partnerData.address) // BT-75
      .up()
    .up()
    .ele('D_3164')
      .txt(invoice.partnerData.city) // BT-77
    .up()
    .ele('D_3251')
      .txt(invoice.partnerData.postOffice) // BT-78
    .up()
    .ele('D_3207')
      .txt('SI') // BT-80
    .up()
  .up();

  // This field is not in the documentation but the BY section has it and also some examples has it. So here we just copy pasted what BY section also has.
  if (invoice.partnerData.countryIssuedId) {
    deliveryInformationElement.ele('G_SG3')
      .ele('S_RFF')
        .ele('C_C506')
          .ele('D_1153')
            .txt('0199')
          .up()
          .ele('D_1154')
            .txt(invoice.partnerData.countryIssuedId) // Not in the documentation, check comment above
          .up()
        .up()
      .up()
    .up();
  }

  // Seller data
  const sellerElement = mInvoiceElement.ele('G_SG2');
  const sellerNadElement = sellerElement.ele('S_NAD');

  sellerNadElement
    .ele('D_3035')
      .txt('SE')
    .up()
    .ele('C_C080')
      .ele('D_3036')
        .txt(getTextAsString(invoice.organizationData.name, 70)) // BT-27
      .up()
    .up()
    .ele('C_C059')
      .ele('D_3042')
        .txt(invoice.organizationData.address) // BT-35
      .up()
    .up()
    .ele('D_3164')
      .txt(invoice.organizationData.city) // BT-37
    .up()
    .ele('D_3251')
      .txt(invoice.organizationData.postOffice) // BT-38
    .up()
    .ele('D_3207')
      .txt('SI') // BT-40
    .up()
  .up();

  // Seller country
  // if (invoice.organizationData.country.length > 0) {
  //   sellerNadElement.ele('C_C819')
  //     .ele('D_3228')
  //       .txt(invoice.organizationData.country)
  //     .up()
  //   .up();
  // }

  // Seller bank transfer details
  const sellerBankElement = sellerElement.ele('S_FII');
  sellerBankElement
    .ele('D_3035')
      .txt('RB')
    .up()
    .ele('C_C078')
      .ele('D_3194')
        .txt(invoice.organizationData.bankAccount)
      .up()
      .ele('D_3192')
        .txt(invoice.organizationData.name.substr(0, 35))
      .up()
    .up()
  .up()

  // Bank BIC code
  if (invoice.organizationData.bankBusinessIdentifierCode) {
    sellerBankElement.ele('C_C088')
      .ele('D_3433')
        .txt(invoice.organizationData.bankBusinessIdentifierCode)
      .up()
    .up();
  }

  // Seller country issued id
  if (invoice.organizationData.countryIssuedId) {
    sellerElement.ele('G_SG3')
      .ele('S_RFF')
        .ele('C_C506')
          .ele('D_1153')
            .txt('0199')
          .up()
          .ele('D_1154')
            .txt(invoice.organizationData.countryIssuedId) // BT-30
          .up()
        .up()
      .up()
    .up();
  }

  // Seller VAT identifier
  sellerElement.ele('G_SG3')
    .ele('S_RFF')
      .ele('C_C506')
        .ele('D_1153')
          .txt('VA')
        .up()
        .ele('D_1154')
          .txt(getTaxNumberAsString(invoice.organizationData.taxNumber)) // BT-31
        .up()
      .up()
    .up()
  .up();

  // Seller tax registration identifier
  sellerElement.ele('G_SG3')
    .ele('S_RFF')
      .ele('C_C506')
        .ele('D_1153')
          .txt('AHP')
        .up()
        .ele('D_1154')
          .txt(getTaxNumberAsString(invoice.organizationData.taxNumber)) // BT-32
        .up()
      .up()
    .up()
  .up();

  const doc = mInvoiceElement

        // Invoice currency
        .ele('G_SG7')
          .ele('S_CUX')
            .ele('C_C504')
              .ele('D_6347')
                .txt('2')
              .up()
              .ele('D_6345')
                .txt(invoice.metadata.currency)
              .up()
            .up()
          .up()
        .up()

        // Payment due date and payment terms
        .ele('G_SG8')
          .ele('S_PAT')
            .ele('D_4279')
              .txt('1')
            .up()
          .up()
          .ele('S_DTM')
            .ele('C_C507')
              .ele('D_2005')
                .txt('13')
              .up()
              .ele('D_2380')
                .txt(dateString(invoice.metadata.liquidationDate)) // BT-9
              .up()
            .up()
          .up()
          .ele('S_PAI')
            .ele('C_C534')
              .ele('D_4461')
                .txt('30') // BT-81
              .up()
            .up()
          .up()
        .up()

        // Document level discounts (we're not supporting this yet)
        // .ele('G_SG16')
        //   .ele('S_ALC')
        //     .ele('D_5463')
        //       .txt('A')
        //     .up()
        //   .up()
        //   .ele('G_SG19')
        //     .ele('S_PCD')
        //       .ele('C_C501')
        //         .ele('D_5245')
        //           .txt('1')
        //         .up()
        //         .ele('D_5482')
        //           .txt(summary.discountPercentage) // Document discount percentage (BT-94)
        //         .up()
        //       .up()
        //     .up()
        //   .up()
        //   .ele('G_SG20')
        //     .ele('S_MOA')
        //       .ele('C_C516')
        //         .ele('D_5025')
        //           .txt('204')
        //         .up()
        //         .ele('D_5004')
        //           .txt(summary.discountPrice) // Document discount amount (BT-92)
        //         .up()
        //       .up()
        //     .up()
        //   .up()
        //   .ele('G_SG22')
        //     .ele('S_TAX')
        //       .ele('D_5283')
        //         .txt('7')
        //       .up()
        //       .ele('C_C241')
        //         .ele('D_5153')
        //           .txt('VAT')
        //         .up()
        //       .up()
        //       .ele('C_C243')
        //         .ele('D_5278')
        //           .txt('25') // Vat rate (BT-9)
        //         .up()
        //       .up()
        //       .ele('D_5305')
        //         .txt('S') // Vat category (BT-95)
        //       .up()
        //     .up()
        //   .up()
        // .up()
        
      .up();

  // Invoice lines
  items.forEach((item, index) => {
    mInvoiceElement
      .ele('G_SG26')
        .ele('S_LIN')
          .ele('D_1082')
            .txt(index + 1) // BT-126
          .up()
        .up()
        .ele('S_IMD')
          .ele('D_7077')
            .txt('F')
          .up()
          .ele('C_C273')
            .ele('D_7008')
              .txt(item.name) // BT-153
            .up()
          .up()
        .up()
        .ele('S_QTY')
          .ele('C_C186')
            .ele('D_6063')
              .txt(47)
            .up()
            .ele('D_6060')
              .txt(getNumberAsString(item.quantity)) // BT-129
            .up()
            .ele('D_6411')
              .txt('C62') // BT-130
            .up()
          .up()
        .up()
        .ele('G_SG27')
          .ele('S_MOA')
            .ele('C_C516')
              .ele('D_5025')
                .txt('203')
              .up()
              .ele('D_5004')
                .txt(getNumberAsString(item.netoPriceSumWithDiscount)) // BT-131
              .up()
            .up()
          .up()
        .up()
        .ele('G_SG27')
          .ele('S_MOA')
            .ele('C_C516')
              .ele('D_5025')
                .txt('38')
              .up()
              .ele('D_5004')
                .txt(getNumberAsString(item.priceSum)) // NBT-031
              .up()
            .up()
          .up()
        .up()
        // .ele('G_SG39')
        //   .ele('S_ALC')
        //     .ele('D_5463')
        //       .txt('C')
        //     .up()
        //   .up()
        //   .ele('G_SG41')
        //     .ele('S_PCD')
        //       .ele('C_C501')
        //         .ele('D_5245')
        //           .txt('2')
        //         .up()
        //         .ele('D_5482')
        //           .txt(getNumberAsString(item.vatPercentage)) // BT-143
        //         .up()
        //       .up()
        //     .up()
        //   .up()
        //   .ele('G_SG42')
        //     .ele('S_MOA')
        //       .ele('C_C516')
        //         .ele('D_5025')
        //           .txt('23')
        //         .up()
        //         .ele('D_5004')
        //           .txt(getNumberAsString(item.vatPrice)) // BT-141
        //         .up()
        //       .up()
        //     .up()
        //   .up()
        // .up()

        // Item net price
        .ele('G_SG29')
          .ele('S_PRI')
            .ele('C_C509')
              .ele('D_5125')
                .txt('AAA')
              .up()
              .ele('D_5118')
                .txt(getNumberAsString(item.netoPriceSumWithDiscount / item.quantity)) // BT-146
              .up()
              .ele('D_5284')
                .txt('1') // BT-149
              .up()
            .up()
          .up()
        .up()

        // Item gross price
        .ele('G_SG29')
          .ele('S_PRI')
            .ele('C_C509')
              .ele('D_5125')
                .txt('AAB')
              .up()
              .ele('D_5118')
                .txt(getNumberAsString(item.price)) // BT-148
              .up()
              .ele('D_5284')
                .txt('1') // BT-149
              .up()
            .up()
          .up()
        .up()

        // Item vat details
        .ele('G_SG34')
          .ele('S_TAX')
            .ele('D_5283')
              .txt('7')
            .up()
            .ele('C_C241')
              .ele('D_5153')
                .txt('VAT')
              .up()
            .up()
            .ele('C_C243')
              .ele('D_5278')
                .txt(getNumberAsString(item.vatPercentage)) // BT-152
              .up()
            .up()
            .ele('D_5305')
              .txt(eInvoiceModel.getInvoicedItemVatCategoryCode(item)) // BT-151
            .up()
          .up()
          
          .ele('S_MOA')
            .ele('C_C516')
              .ele('D_5025')
                .txt('125')
              .up()
              .ele('D_5004')
                .txt(getNumberAsString(item.netoPriceSumWithDiscount)) // NBT-032
              .up()
            .up()
          .up()
          
          .ele('S_MOA')
            .ele('C_C516')
              .ele('D_5025')
                .txt('124')
              .up()
              .ele('D_5004')
                .txt(getNumberAsString(item.vatPrice)) // NBT-033
              .up()
            .up()
          .up()

        .up()

        .ele('G_SG39')
          .ele('S_ALC')
            .ele('D_5463')
              .txt('A')
            .up()
            .ele('C_C552')
              .ele('D_5189')
                .txt('95') // Discount allowance reason code (BT-140)
              .up()
            .up()
          .up()
          .ele('G_SG41')
            .ele('S_PCD')
              .ele('C_C501')
                .ele('D_5245')
                  .txt('1')
                .up()
                .ele('D_5482')
                  .txt(getNumberAsString(item.discountPercentage)) // Discount percentage (BT-138)
                .up()
              .up()
            .up()
          .up()
          .ele('G_SG42')
            .ele('S_MOA')
              .ele('C_C516')
                .ele('D_5025')
                  .txt('204')
                .up()
                .ele('D_5004')
                  .txt(item.discountPrice) // Discount price (BT-136)
                .up()
              .up()
            .up()
          .up()
        .up()

      .up()
  });
  
  // Invoice total amount without vat
  mInvoiceElement
    .ele('G_SG50')
      .ele('S_MOA')
        .ele('C_C516')
          .ele('D_5025')
            .txt('389')
          .up()
          .ele('D_5004')
            .txt(getNumberAsString(summary.netoPriceSumWithDiscount)) // BT-109
          .up()
        .up()
      .up()
    .up();

  // Invoice total vat amount
  mInvoiceElement
  .ele('G_SG50')
    .ele('S_MOA')
      .ele('C_C516')
        .ele('D_5025')
          .txt('176')
        .up()
        .ele('D_5004')
          .txt(getNumberAsString(summary.vatPrice)) // BT-110
        .up()
      .up()
    .up()
  .up();

  // Invoice total amount with vat
  mInvoiceElement
  .ele('G_SG50')
    .ele('S_MOA')
      .ele('C_C516')
        .ele('D_5025')
          .txt('388')
        .up()
        .ele('D_5004')
          .txt(getNumberAsString(summary.priceSum)) // BT-112
        .up()
      .up()
    .up()
  .up();

  // Amount due for payment
  mInvoiceElement
  .ele('G_SG50')
    .ele('S_MOA')
      .ele('C_C516')
        .ele('D_5025')
          .txt('9')
        .up()
        .ele('D_5004')
          .txt(getNumberAsString(summary.priceSum)) // BT-115
        .up()
      .up()
    .up()
  .up();

  // Invoice sum of invoice line net amounts
  mInvoiceElement
    .ele('G_SG50')
      .ele('S_MOA')
        .ele('C_C516')
          .ele('D_5025')
            .txt('79')
          .up()
          .ele('D_5004')
            .txt(getNumberAsString(summary.netoPriceSumWithDiscount)) // BT-106
          .up()
        .up()
      .up()
    .up();

    // Vat breakdown
    vatCategories.forEach(vatCategory => {
      mInvoiceElement
      .ele('G_SG52')
        .ele('S_TAX')
          .ele('D_5283')
            .txt('7')
          .up()
          .ele('C_C241')
            .ele('D_5153')
              .txt('VAT')
            .up()
          .up()
          .ele('C_C243')
            .ele('D_5278')
              .txt(getNumberAsString(vatCategory.rate)) // BT-119
            .up()
          .up()
          .ele('D_5305')
            .txt(eInvoiceModel.getVatCategoryCode(vatCategory)) // BT-118
          .up()
        .up()
        .ele('S_MOA')
          .ele('C_C516')
            .ele('D_5025')
              .txt('125')
            .up()
            .ele('D_5004')
              .txt(getNumberAsString(vatCategory.taxableAmount)) // BT-116
            .up()
          .up()
        .up()
        .ele('S_MOA')
          .ele('C_C516')
            .ele('D_5025')
              .txt('124')
            .up()
            .ele('D_5004')
              .txt(getNumberAsString(vatCategory.taxAmount)) // BT-117
            .up()
          .up()
        .up()
      .up();
    });

  return doc.toString({ pretty: true });
};

export default { downloadXml, getXmlBase64, generateXml };

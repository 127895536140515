import React from 'react';
import { useAuth } from 'utils/Auth';
import { ReactComponent as StarIcon } from 'icons/star.svg';
import Bugsnag from '@bugsnag/js';
import { useFirebase } from 'config/Firebase';

const useInternalPartnerSearch = () => {
  
  let allOptions;
  let searching;
  let lastQuery;

  const currentUser = useAuth().currentUser;
  const firebaseContext = useFirebase();

  const get = async (queryText) => {
    lastQuery = queryText;

    if (searching) {
      return;
    }

    if (allOptions) {
      return filteredOptions();
    }
    
    searching = true;
    
    const db = firebaseContext.firebase.db();
    const query = db.collection('partners').where('authorUid', '==', currentUser.uid);

    try {
      const snapshot = await query.get();
      allOptions = mapToOptions(snapshot);
      searching = false;
  
      return filteredOptions();
    }
    catch (error) {
      Bugsnag.notify(error);
      console.log(`Error while retrieving select box options: ${error}`);
    }
  };

  const mapToOptions = (snapshot) => {
    let options = [];
    
    snapshot.forEach(document => {
      const data = document.data();
      const option = {
        value: document.id,
        label: data.name,
        detailedLabel: (<><span className="fav-star"><StarIcon /></span>{data.name}</>),
        organization: {
          id: document.id,
          name: data.name,
          address: data.address,
          postOffice: data.postOffice,
          city: data.city,
          country: data.country,
          taxNumber: data.taxNumber,
          countryIssuedId: data.countryIssuedId,
          email: data.email,
          glnCode: data.glnCode,
          bankAccount: data.bankAccount,
          bankBusinessIdentifierCode: data.bankBusinessIdentifierCode,
        }
      };
      options.push(option);
    });

    return options;
  };

  const filteredOptions = () => {
    if (lastQuery.length === 0) {
      return allOptions;
    }

    const searchTerms = lastQuery.toLowerCase().split(' ');
    return allOptions.filter(function(option) {
      return searchTerms.every(function(el) {
        const name = option.organization.name;
        const taxNumber = option.organization.taxNumber;
        return name.toLowerCase().indexOf(el) > -1 || taxNumber.toLowerCase().indexOf(el) > -1;
      });
    });
  };
  
  return { get };

};

export default useInternalPartnerSearch;

import InvoiceType from '../types/InvoiceType';

export default class InvoiceTypeProvider {

  private static all: InvoiceType[] = [
    { // Regular
      id: '0',
      eSlogInvoiceTypeCode: '380',
      impactsOnInvoiceStats: true,
      allowsCreatingInvoice: false
    },
    { // Proforma
      id: '1',
      eSlogInvoiceTypeCode: '325',
      impactsOnInvoiceStats: false,
      allowsCreatingInvoice: true
    },
    { // Prepayment
      id: '2',
      eSlogInvoiceTypeCode: '386',
      impactsOnInvoiceStats: true,
      allowsCreatingInvoice: false
    }
  ]

  static getDefault() {
    return this.all[0];
  }

  static getById(id: string | undefined) {
    if (!id) {
      return this.getDefault();
    }

    let foundInvoiceType: InvoiceType | null = null;
    
    this.all.forEach(invoiceType => {
      if (invoiceType.id === id) {
        foundInvoiceType = invoiceType;
      }
    });

    if (!foundInvoiceType) {
      throw new Error(`Unable to find invoice type: ${id}`)
    }

    return foundInvoiceType;
  }

  static getAll() {
    return this.all;
  }

  static getESlogInvoiceTypeCode(invoiceTypeId: string) {
    const invoiceType = this.getById(invoiceTypeId);
    return invoiceType.eSlogInvoiceTypeCode;
  }
  
}
